import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SetCommissionModal = ({ show, handleClose, clientName, applicationId, onConfirm }) => {
    const [amount, setAmount] = useState('');
    const [percentage, setPercentage] = useState('');
    const [calculatedCommission, setCalculatedCommission] = useState(0);
    const [confirmWarning, setConfirmWarning] = useState(false);
    const [commissionPaid, setCommissionPaid] = useState(false);

    const handleAmountChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setAmount(value);
            if (percentage) {
                calculateCommission(value, percentage);
            }
        }
    };

    const handlePercentageChange = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setPercentage(value);
            calculateCommission(amount, value);
        }
    };

    const calculateCommission = (amount, percentage) => {
        const commission = (amount * percentage) / 100;
        setCalculatedCommission(commission);
    };

    const handleConfirm = async () => {
        setConfirmWarning(false);
        try {
            await onConfirm(calculatedCommission, percentage);
            setCommissionPaid(true);
        } catch (error) {
            console.error('Error submitting commission', error);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-lg">
            <Modal.Header closeButton>
                <Modal.Title className='!text-[#04394F] !font-bold'>
                    Referral "{clientName}'s" Commission
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    <strong>Application ID:</strong> #{applicationId}
                </div>
                <Form>
                    <Form.Group controlId="amount">
                        <Form.Label>Enter Amount</Form.Label>
                        <div className="flex items-center relative">
                            <Form.Control
                                type="text"
                                value={amount}
                                onChange={handleAmountChange}
                                placeholder="Enter amount"
                            />
                            <span className="absolute right-3">$</span>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="percentage" className="mt-3">
                        <Form.Label>Enter Percentage</Form.Label>
                        <div className="flex items-center relative">
                            <Form.Control
                                type="text"
                                value={percentage}
                                onChange={handlePercentageChange}
                                disabled={!amount}
                                placeholder="Enter percentage"
                            />
                            <span className="absolute right-3">%</span>
                        </div>
                    </Form.Group>
                    <div className={`mt-3 ${amount && percentage ? 'opacity-100' : 'opacity-50'}`}>
                        <strong>Commission: ${calculatedCommission.toFixed(2)}</strong>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {commissionPaid ? (
                    <Button variant="success" disabled>
                        Commission Paid
                    </Button>
                ) : (
                    <>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button
                            variant="warning"
                            onClick={() => setConfirmWarning(true)}
                            disabled={!amount || !percentage}
                        >
                            Confirm
                        </Button>
                    </>
                )}
            </Modal.Footer>


            {/* Confirmation Warning Modal */}
            {confirmWarning && (
                <Modal show={confirmWarning} onHide={() => setConfirmWarning(false)} centered>
                    <Modal.Header closeButton>
                        <Modal.Title className='!text-[#04394F] !font-bold'>Warning</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure? This action cannot be reverted.</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setConfirmWarning(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleConfirm}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </Modal>
    );
};

export default SetCommissionModal;
