import React, { createContext, useContext, useState } from 'react';

// Create the context
const SearchContext = createContext();

// Create a provider component
export const SearchProvider = ({ children }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearchVisible, setIsSearchVisible] = useState(false);

    return (
        <SearchContext.Provider value={{ searchQuery, setSearchQuery, isSearchVisible, setIsSearchVisible }}>
            {children}
        </SearchContext.Provider>
    );
};

// Hook to use search context in components
export const useSearch = () => useContext(SearchContext);
