import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import AdminSidebar from '../AdminComponents/AdminSideBar';
import AdminNavbarComponent from '../AdminComponents/AdminNavbarHeader';
import useAuth from '../hooks/useAuth';
import useRequireAuthAdmin from '../hooks/useRequireAuthAdmin';

const AdminDashboardLayout = () => {
    const [showSidebar, setShowSidebar] = useState(false);

    // Toggle sidebar visibility for mobile view
    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };
    // useAuth();
    useRequireAuthAdmin();
    return (
        <Row className="h-screen">
            {/* Sidebar Column */}
            <Col xs={12} md={2} className="p-0 bg-[#003049] h-screen overflow-hidden">
                <AdminSidebar show={showSidebar} toggleSidebar={toggleSidebar} />
            </Col>

            {/* Main Content Column */}
            <Col xs={12} md={10} className="d-flex h-screen relative">
                {/* Navbar - Fixed on top */}
                <div className="absolute top-0 left-0 w-[100%]" style={{ zIndex: 1050 }}>
                    <AdminNavbarComponent toggleSidebar={toggleSidebar} />
                </div>

                {/* Scrollable Outlet below Navbar */}
                <div className=" flex-grow overflow-auto pt-5" style={{ marginTop: '56px' }}>
                    <Outlet />
                </div>
            </Col>
        </Row>
    );
};

export default AdminDashboardLayout;
