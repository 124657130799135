import React from 'react';
import { Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation to get the current URL
import '../../styles/hamburgerMenu.css'; // Contains overlay and mobile styling
import pinnacleLogo from "../../assets/Pinnacle-Logo-White 2.svg"
import Cookies from 'js-cookie'; // Import js-cookie
import { AiFillWindows, AiFillFileText } from 'react-icons/ai';
import { BsFillChatTextFill } from 'react-icons/bs';
import { BiLogOutCircle } from 'react-icons/bi';
import { FaCog } from 'react-icons/fa';


const Sidebar = ({ show, toggleSidebar }) => {
    const location = useLocation(); // Get the current URL
    const navigate = useNavigate('/login')

    // Function to check if the current Nav.Link is active
    const isActive = (path) => location.pathname === path;

    const handleLogout = () => {
        // Remove the authentication cookie (change 'authToken' to your cookie name)
        Cookies.remove('user');

        // Navigate to the login page
        navigate('/login');
    };

    return (
        <>
            {/* Mobile Sidebar - Overlay Mode */}
            <div className={`sidebar-mobile ${show ? 'show' : ''}`}>
                <div className="sidebar-content bg-[#04394F] text-white rounded-md h-full">
                    <Nav className="flex-column">
                        <Nav.Link
                            href="/dashboard"
                            className={`${isActive('/dashboard') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md`}
                        >
                            <i className="fas fa-tachometer-alt"></i> Dashboard
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboard/myapplications"
                            className={`${isActive('/dashboard/myapplications') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md`}
                        >
                            <i className="fas fa-file-alt"></i> My Applications
                        </Nav.Link>
                        <Nav.Link
                            href="/dashboard/ReferralDashboard"
                            className={`${isActive('/dashboard/ReferralDashboard') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md`}
                        >
                            <i className="fas fa-share-alt"></i> Referral
                        </Nav.Link>
                        {/* <Nav.Link 
                            // href="/messages" 
                            className={`${isActive('/messages') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md`}
                        >
                            <i className="fas fa-envelope"></i> Messages
                        </Nav.Link> */}
                        {/* <Nav.Link 
                            // href="/settings" 
                            className={`${isActive('/settings') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md`}
                        >
                            <i className="fas fa-cog"></i> Settings
                        </Nav.Link> */}
                        <Nav.Link
                            // href="/login"
                            onClick={handleLogout}
                            className={`${isActive('/logout') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md`}
                        >
                            <i className="fas fa-sign-out-alt"></i> Log Out
                        </Nav.Link>
                    </Nav>
                </div>
                {show && <div className="overlay" onClick={toggleSidebar}></div>}
            </div>

            {/* Desktop Sidebar */}
            <div className="hidden md:block bg-[#04394F] min-h-screen text-white rounded-md px-4 border-r border-solid border-r-[#B38D2E]">
                <div className='flex justify-center pt-3'>
                    <img src={pinnacleLogo} alt='pinnacle Logo'></img>
                </div>
                <div className='mt-[10%] px-[10%]'>
                    <div className='bg-[#B38D2E] h-[1px]'></div>
                </div>
                <Nav className="flex-column py-3 px-1 space-y-6">
                    <div className='space-y-3'>
                        <Nav.Link
                            onClick={() => navigate('/dashboard')}
                            className={`!flex items-center ${isActive('/dashboard') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md`}
                        >
                            <AiFillWindows className="mr-2 text-white" /> Dashboard
                        </Nav.Link>

                        <Nav.Link
                            onClick={() => navigate('/dashboard/myapplications')}
                            className={`!flex items-center ${isActive('/dashboard/myapplications') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md`}
                        >
                            <AiFillFileText className="mr-2 text-white" /> My Applications
                        </Nav.Link>

                        <Nav.Link
                            onClick={() => navigate('/dashboard/ReferralDashboard')}
                            className={`!flex items-center ${isActive('/dashboard/ReferralDashboard') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md`}
                        >
                            <BsFillChatTextFill className="mr-2 text-white" /> Referral
                        </Nav.Link>
                    </div>
                    <div className='space-y-3'>
                        <Nav.Link 
                            onClick={() => navigate('/dashboard/settings')} // Navigate programmatically
                            className={`!flex items-center ${isActive('/dashboard/settings') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white !font-medium'} rounded-md hover:bg-[#B38D2E]  hover:rounded-md`}
                        >
                            <FaCog className="mr-2" /> Settings
                        </Nav.Link>
                        <Nav.Link
                            onClick={handleLogout}
                            className={`!flex items-center ${isActive('/logout') ? '!bg-[#B38D2E] text-white !font-semibold' : 'text-white !font-medium'} rounded-md hover:bg-[#B38D2E] hover:rounded-md`}
                        >
                            <BiLogOutCircle className="mr-2 text-white" /> Log Out
                        </Nav.Link>
                    </div>
                </Nav>
            </div>
        </>
    );
};

export default Sidebar;
