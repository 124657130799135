import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Spinner, Alert } from 'react-bootstrap';
import '../../styles/AdminTable.css';
import { Menu } from '@headlessui/react';
import { FaEllipsisV } from 'react-icons/fa';
import InviteClientModal from './InviteClientModal';
import { useNavigate } from 'react-router-dom';
import { url } from '../../utils/networkconfig';

const ClientsOverview = () => {
    const [clientsData, setClientsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();


    // Function to get status-specific CSS classes
    const getStatusClass = (status) => {
        switch (status) {
            case 'completed':
                return 'bg-[#00B67A] text-white px-2 py-1 rounded-full';
            case 'in_progress':
                return 'bg-[#E8DDC0] text-black px-2 py-1 rounded-full';
            case 'under_review':
                return 'bg-[#E3E3E3] text-black px-2 py-1 rounded-full';
            case 'approved':
                return 'bg-[#00B67A] text-white px-2 py-1 rounded-full';
            case 'rejected':
                return 'bg-red-500 text-white px-2 py-1 rounded-full';
            case 'submitted':
                return 'bg-green-300 text-white px-2 py-1 rounded-full';
            default:
                return 'bg-gray-300 text-black px-2 py-1 rounded-full';
        }
    };

    // Fetch data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${url}/api/latest-5-users/`);
                setClientsData(response.data.latest_users);
                setLoading(false);
            } catch (err) {
                setError('Error fetching clients data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return (
            <div className="text-center mt-4">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center mt-4">
                <Alert variant="danger">{error}</Alert>
            </div>
        );
    }

    if (clientsData.length === 0) {
        return (
            <div className="text-center mt-4">
                <Alert variant="warning">No Clients Found</Alert>
            </div>
        );
    }

    return (
        <div className="p-4 border rounded-md shadow-md">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Clients Overview</h3>
                <Button onClick={() => setShowModal(true)} variant="warning">Invite new client</Button>
            </div>
            <div className="max-h-[252px] overflow-y-auto">
                <Table hover responsive className="admin-table">
                    <thead>
                        <tr>
                            <th>Client Name</th>
                            <th>Business Name</th>
                            <th>Application Status</th>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientsData.map((client, index) => (
                            <tr key={index}>
                                <td>{client.full_name}</td>
                                <td>{client.business_name}</td>
                                <td style={{ whiteSpace: 'nowrap', padding: '0.75rem 1rem' }}>
                                    <span className={getStatusClass(client.application_status)}>
                                        {client.application_status.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                    </span>
                                </td>
                                <td>{client.referrer}</td>
                                <td>
                                    <Menu as="div" className="relative inline-block text-left">
                                        <Menu.Button>
                                            <FaEllipsisV />
                                        </Menu.Button>
                                        <Menu.Items className="origin-top-right absolute right-3 bottom-[-10px] mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                            <div className="py-1 Barlow">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href="#"
                                                            onClick={() => navigate(`/admin/dashboard/application-details/${client.application_id}/`)}
                                                            className={`block px-4 py-2 text-xs !no-underline ${active ? 'bg-gray-100' : ''}`}
                                                        >
                                                            View Details
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                {/* <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                className={`block px-4 py-2 text-xs !no-underline ${active ? 'bg-gray-100' : ''}`}
                                            >
                                                Send Reminder
                                            </a>
                                        )}
                                    </Menu.Item> */}
                                            </div>
                                        </Menu.Items>
                                    </Menu>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>


            <InviteClientModal show={showModal} handleClose={() => setShowModal(false)} />
        </div>
    );
};

export default ClientsOverview;
