import React, { useContext, useEffect, useState } from 'react';
import { BannerContext } from '../../context/bannerContext';
import { ProgressContext } from '../../context/ProgressContext';
import { Button, Form, Toast, Spinner } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { url } from '../../utils/networkconfig';

const Step3 = () => {
    const { setBannerState } = useContext(BannerContext);
    const { setProgress } = useContext(ProgressContext);
    const navigate = useNavigate();
    const location = useLocation()
    const [loanAmount, setLoanAmount] = useState('');
    const [financeType, setFinanceType] = useState(''); // Added finance type state
    const [purpose, setPurpose] = useState('');
    const [repaymentPeriod, setRepaymentPeriod] = useState('');
    const [preferredFundingTimeline, setPreferredFundingTimeline] = useState('');
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [isUpdate, setIsUpdate] = useState(false); // Flag to determine if it's an update

    useEffect(() => {
        setProgress({
            text: "4 more steps to go. Almost there!",
            percentage: 33.34 // Update percentage based on the step
        });
    }, []);

    useEffect(() => {
        setBannerState({
            title: 'Funding Requirements',
            information: "This stage gathers specific financial information about the user's needs. The form adjusts based on answers.",
        });
    }, [setBannerState]);

    useEffect(() => {
        const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
        const application_id = user ? user.application_id : null;

        if (application_id) {
            setLoading(true);
            axios.get(`${url}/api/applications/${application_id}/funding-requirements/`)
                .then(response => {
                    const data = response.data;
                    // Check if the data exists to determine if we're updating
                    if (data) {
                        setLoanAmount(data.amount_required || '');
                        setFinanceType(data.finance_type || ''); // Set finance type
                        setPurpose(data.purpose || '');
                        setRepaymentPeriod(data.repayment_period || '');
                        setPreferredFundingTimeline(data.preferred_funding_timeline || '');
                        setIsUpdate(true); // Set flag to true for update
                    }
                })
                .catch(error => {
                    console.error("Error fetching financial information:", error);
                })
                .finally(() => setLoading(false));
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        
        const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
        const application_id = user ? user.application_id : null;

        const fundingData = {
            application: application_id,
            amount_required: loanAmount,
            finance_type: financeType, // Include finance type in the request
            purpose: purpose,
            repayment_period: repaymentPeriod,
            preferred_funding_timeline: preferredFundingTimeline,
        };

        // Form validation can be done here
        if (!loanAmount || !financeType || !purpose || !repaymentPeriod || !preferredFundingTimeline) {
            setToastMessage('Please fill all required fields.');
            setToastType('danger');
            setShowToast(true);
            setLoading(false);
            return;
        }

        const request = isUpdate
            ? axios.put(`${url}/api/applications/${application_id}/funding-requirements/`, fundingData)
            : axios.post(`${url}/api/applications/${application_id}/funding-requirements/`, fundingData);

        request
            .then(response => {
                setToastMessage('Funding requirements submitted successfully.');
                setToastType('success');
                setShowToast(true);
                const queryParams = new URLSearchParams(location.search);
                if (queryParams.get('edit') === 'true') {
                    navigate('/steps/step6'); // Navigate back to the previous page
                } else {
                    navigate('/steps/step4'); // Otherwise, proceed to the next step
                }
            })
            .catch(error => {
                console.error("Error submitting funding requirements:", error);
                setToastMessage('Failed to submit funding requirements.');
                setToastType('danger');
                setShowToast(true);
            })
            .finally(() => setLoading(false));
    };

    return (
        <div>
            <Form className="pt-[8%]" onSubmit={handleSubmit}>
                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%]">Funding Requirements</h2>
                <div className='max-h-[40vh] overflow-y-auto custom-scrollbar pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>
                        {/* Finance Type Dropdown */}
                        <Form.Group controlId="formFinanceType">
                            <Form.Label>Finance Type<span className="text-red-500">*</span></Form.Label>
                            <Form.Select
                                value={financeType}
                                onChange={(e) => setFinanceType(e.target.value)}
                                disabled={loading}
                            >
                                <option value="">Select</option>
                                <option value="business_loan">Business Loan</option>
                                <option value="invoice_finance">Invoice Finance</option>
                                <option value="revolving_credit">Revolving Credit</option>
                                <option value="merchant_cash_advance">Merchant Cash Advance</option>
                                <option value="bridging_loan">Bridging Loan</option>
                                <option value="commercial_mortgage">Commercial Mortgage</option>
                                <option value="development_finance">Development Finance</option>
                                <option value="asset_finance">Asset Finance</option>
                                <option value="other">Other</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="formLoanAmount">
                            <Form.Label>Amount Required<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the amount you are requesting"
                                value={loanAmount}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Allow only numbers and decimal points
                                    if (/^\d*\.?\d*$/.test(inputValue)) {
                                        setLoanAmount(inputValue);
                                    }
                                }}
                                disabled={loading}
                            />
                        </Form.Group>

                        <Form.Group controlId="formIndustrySector">
                            <Form.Label>Purpose<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter your text here"
                                value={purpose}
                                onChange={(e) => setPurpose(e.target.value)}
                                disabled={loading}
                            />
                        </Form.Group>

                        <Form.Group controlId="formRepaymentPeriod">
                            <Form.Label>Repayment Period<span className="text-red-500">*</span></Form.Label>
                            <Form.Select
                                value={repaymentPeriod}
                                onChange={(e) => setRepaymentPeriod(e.target.value)}
                                disabled={loading}
                            >
                                <option value="">Select</option>
                                <option value="1 - 2 Years">1 - 2 Years</option>
                                <option value="2 - 3 Years">2 - 3 Years</option>
                                <option value="3 - 5 Years">3 - 5 Years</option>
                                <option value="More than 5 Years">More than 5 Years</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="formPreferredFundingTimeline">
                            <Form.Label>Preferred Funding Timeline<span className="text-red-500">*</span></Form.Label>
                            <Form.Select
                                value={preferredFundingTimeline}
                                onChange={(e) => setPreferredFundingTimeline(e.target.value)}
                                disabled={loading}
                            >
                                <option value="">Select</option>
                                <option value="1 - 2 Years">1 - 2 Years</option>
                                <option value="2 - 3 Years">2 - 3 Years</option>
                                <option value="3 - 5 Years">3 - 5 Years</option>
                                <option value="More than 5 Years">More than 5 Years</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div
                    className="absolute bottom-[22%] left-0 right-0 h-8 "
                    style={{
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.14) 16%, rgba(102, 102, 102, 0) 100%)',
                        transform: 'scaleY(1)', // This inverts the gradient vertically
                    }}
                ></div>
                <div className='pt-[10%] px-[10%] pb-[2%] flex gap-2'>
                    <button
                        className="p-2 px-3 h-12 hover:text-white hover:bg-[#f1b00c] transition-colors ease-in flex items-center justify-center border-[1px] rounded-md border-[#111111]"
                        onClick={() => (navigate("/steps/step2"))}
                        disabled={loading}
                    >
                        <FaArrowLeft /> {/* Arrow Icon */}
                    </button>
                    <Button
                        variant="warning"
                        className="w-full h-10 text-white rounded-md"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Continue'}
                    </Button>
                </div>
            </Form>

            {/* Toast for Success/Error Messages */}
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                delay={3000}
                autohide
                className={`bg-${toastType === 'success' ? 'success' : 'danger'} text-white`}
                style={{ position: 'absolute', top: '10%', right: '10%', zIndex: 1050 }}
            >
                <Toast.Body className='!text-white font-medium'>{toastMessage}</Toast.Body>
            </Toast>
        </div>
    )
};

export default Step3;
