import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import fileIcon from '../../assets/fileIcon.svg';
import { url } from '../../utils/networkconfig';

const DocumentsList = ({ documents, handleOpenMessageModal, name, email, application_id,  docStatus, setDocStatus  }) => {
    // const [docStatus, setDocStatus] = useState(
    //     documents.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.status }), {})
    // );

    // Function to handle PUT request to update the document status
    const updateDocumentStatus = async (docId, status) => {
        try {
            const response = await axios.put(`${url}/api/document/${docId}/update/`, { status });
            if (response.status === 200) {
                setDocStatus((prevState) => ({
                    ...prevState,
                    [docId]: status, // Update the local state with the new status
                }));
            }
        } catch (error) {
            console.error('Error updating document status:', error);
        }
    };

    const handleAction = (docId, action) => {
        updateDocumentStatus(docId, action);
    };

    const renderDocumentRow = (doc) => {
        const fileName = doc.file.split('/').pop(); // Extract filename from URL
        const status = docStatus[doc.id]; // Get the status for this document

        // Determine if action buttons should be disabled or hidden
        const isUnderReview = status === 'under_review';
        const isAccepted = status === 'accepted';
        const isRejected = status === 'rejected';
        const isReminderPending = status === 'reminder_pending';

        return (
            <Row key={doc.id} className="mb-3 border-b pb-2">
                <Col md={6}>
                    <div className="flex items-center">
                        <div>
                            <img src={fileIcon} alt="document-icon" className="mr-2 w-6 h-6" />
                        </div>
                        <div className="flex-col">
                            <div>
                                <a href={doc.file} target="_blank" rel="noopener noreferrer" className="text-sm !no-underline">
                                    {fileName} <span className="text-sm text-gray-500">(5.7 MB)</span>  {/* Update size as needed */}
                                </a>
                            </div>
                            <div>
                                <a href={doc.file} download className="text-sm Barlow">Download</a>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6} className="text-start">
                    {isUnderReview ? (
                        <div className="flex gap-3 justify-start">
                            <button
                                className="underline text-green-500 font-semibold text-sm"
                                onClick={() => handleAction(doc.id, 'accepted')}
                            >
                                Accept
                            </button>
                            <button
                                className="underline text-red-500 font-semibold text-sm"
                                onClick={() => handleAction(doc.id, 'rejected')}
                            >
                                Reject
                            </button>
                            <button
                                className="underline text-orange-500 font-semibold text-sm"
                                onClick={() => handleOpenMessageModal(name, email, application_id, doc.id)} // Open modal with document_id
                            >
                                Send reminder to upload again
                            </button>
                        </div>
                    ) : (
                        <p className={`font-bold ${isAccepted ? 'text-green-500' : isRejected ? 'text-red-500' : 'text-orange-500'}`}>
                            {status.charAt(0).toUpperCase() + status.slice(1).replace('_', ' ')}
                        </p>
                    )}
                </Col>
            </Row>
        );
    };

    const groupedDocuments = documents.reduce((acc, doc) => {
        (acc[doc.label] = acc[doc.label] || []).push(doc);
        return acc;
    }, {});

    return (
        <div className="border rounded-md p-4 bg-white">
            <div className="flex justify-between items-center mb-4">
                <h4 className="text-lg font-semibold text-[#04394F]">Documents</h4>
                <Button
                    variant="warning"
                    className="bg-yellow-600 text-white"
                    onClick={() => handleOpenMessageModal(name, email, application_id)} // Open modal without document_id
                >
                    Ask additional documents
                </Button>
            </div>
            {Object.entries(groupedDocuments).map(([label, docs]) => (
                <div key={label}>
                    <h5 className="font-semibold">{label}</h5>
                    {docs.map(renderDocumentRow)}
                </div>
            ))}
        </div>
    );
};

export default DocumentsList;
