import React from 'react';
import GaugeChart from 'react-gauge-chart';
import PropTypes from 'prop-types';

const ProgressGauge = ({ total, completed, inProgress, rejected }) => {
    // Handle rendering errors and default values
    const totalApplications = total || 0;
    const completedApplications = completed || 0;
    const inProgressApplications = inProgress || 0;
    const rejectedApplications = rejected || 0;

    // Calculate the percentage
    const percentage = totalApplications
        ? ((completedApplications / totalApplications) * 100).toFixed(2)
        : 0;

    return (
        <div className="flex flex-col items-center justify-center p-4 border rounded-md shadow-md">
            <h3 className="text-lg font-semibold mb-4">Overall Progress</h3>
            <GaugeChart
                id="gauge-chart"
                nrOfLevels={20}
                arcsLength={[0.33, 0.33, 0.33]}
                colors={['#4CAF50', '#FF9800', '#F44336']}
                percent={percentage / 100}
                hideText={true}
                arcPadding={0.02}
                style={{ width: '300px', height: '150px' }}
            />
            <div className="mt-4 flex justify-around w-full">
                <p className="text-success">{completedApplications} Completed</p>
                <p className="text-warning">{inProgressApplications} In Progress</p>
                <p className="text-danger">{rejectedApplications} Rejected</p>
            </div>
            <div className="text-center mt-4">
                <h4 className="text-xl font-bold">{percentage}% Performance</h4>
            </div>
        </div>
    );
};

ProgressGauge.propTypes = {
    total: PropTypes.number,
    completed: PropTypes.number,
    inProgress: PropTypes.number,
    rejected: PropTypes.number,
};

export default ProgressGauge;
