import React, { createContext, useContext, useEffect, useState } from 'react';
import { url } from '../utils/networkconfig';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import profilePic from '../assets/pinnacle-logo-avatar.png';


// Create the context
export const ProfileContext = createContext();

// Create a provider component
export const ProfileProvider = ({ children }) => {
    const navigate = useNavigate();
    const userCookie = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const adminCookie = Cookies.get('admin') ? JSON.parse(Cookies.get('admin')) : null;

    // Prioritize 'user' over 'admin'
    const user = userCookie || adminCookie;

    // Extract user_id if available
    const user_id = user ? user.user_id : null;

    const [ProfileUrl, setProfileUrl] = useState('');

    const [userdata, setData] = useState({
        fullName: '',
        phoneNo: '',
        email: '',
        password: '',
        imageUrl: profilePic, // Default image URL
    });



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${url}/api/user/${user_id}/profile/`);
                const responseData = await response.json();

                if (response.ok) {
                    // Setting data based on API response
                    setData({
                        fullName: responseData?.admin_name,
                        imageUrl: responseData?.profile_picture || profilePic,
                    });
                    setProfileUrl(responseData.profile_picture || profilePic)
                } else {
                    console.error('Failed to load user data');
                }
            } catch (error) {
                console.error('An error occurred while fetching data');
            }
        };

        fetchData();
    }, [user_id,navigate]);

    return (
        <ProfileContext.Provider value={{ userdata, ProfileUrl, setProfileUrl, setData, profilePic }}>
            {children}
        </ProfileContext.Provider>
    );
};

