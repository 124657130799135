// NavbarComponent.js

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navbar, Button, Form, FormControl, InputGroup, Dropdown, Spinner, Table } from 'react-bootstrap';
import { FaBars, FaSearch, FaBell } from 'react-icons/fa';
import profilePic from '../../assets/Avatar.png'; // Assuming you have a profile picture asset
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';
import { ProfileContext } from '../../context/ProfileContext';



const NavbarComponent = ({ toggleSidebar }) => {

    const { ProfileUrl } = useContext(ProfileContext)
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false); // State to control dropdown visibility
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const [showSearchResults, setShowSearchResults] = useState(false); // Toggle search results display
    const [filteredApplications, setFilteredApplications] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isSearching, setIsSearching] = useState(false); // Track if the search is in progress
    const searchRef = useRef(null);
    const searchResultsRef = useRef(null);
    const userId = user ? user.user_id : null;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${url}/api/notifications/${userId}/`);
                setNotifications(response.data.notifications);
            } catch (error) {
                setError('Error fetching notifications');
            } finally {
                setLoading(false);
            }
        };

        fetchNotifications();
    }, [userId]);

    const handleNotificationClick = async (notification) => {
        try {
            // Call API to update the notification status to 'seen'
            await axios.post(`${url}/api/notifications/mark_seen/`, {
                notification_id: notification.notification_id,
            });
            setNotifications((prevNotifications) =>
                prevNotifications.filter((n) => n.notification_id !== notification.notification_id)
            );
            setShowDropdown(false);
            // Navigate to application details page
            navigate(`/dashboard/application-details/${notification.application_id}`)
        } catch (error) {
            console.error('Error marking notification as seen', error);
        }
    };

    const handleSearchClick = async () => {
        if (!searchQuery.trim()) return; // Prevent search if query is empty
        setIsSearching(true); // Start loading
        setShowSearchResults(true); // Show search results box

        try {
            const response = await axios.get(`${url}/api/search_applications/${userId}/?q=${searchQuery}`);
            setFilteredApplications(response.data.applications);
        } catch (error) {
            console.error('Error searching applications', error);
            setFilteredApplications([]); // Handle no results found
        } finally {
            setIsSearching(false); // End loading
        }
    };

    const handleApplicationClick = (applicationId) => {
        navigate(`/dashboard/application-details/${applicationId}`);
        setShowSearchResults(false); // Close search results popup
    };

    const handleClickOutside = (event) => {
        // Check if the click is outside the input field and the search results container
        if (
            searchRef.current &&
            !searchRef.current.contains(event.target) &&
            searchResultsRef.current &&
            !searchResultsRef.current.contains(event.target)
        ) {
            setShowSearchResults(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'submitted':
                return 'bg-gray-100 text-gray-800';
            case 'in_progress':
                return 'bg-yellow-100 text-yellow-800';
            case 'under_review':
                return 'bg-blue-100 text-blue-800';
            case 'approved':
                return 'bg-green-100 text-green-800';
            case 'rejected':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };


    const notificationCount = notifications.length;

    return (
        <Navbar
            expand="lg"
            className="fixed top-0 left-0 w-full z-50 flex items-center justify-between px-4 py-3 bg-[#04394F]"
        >
            <div className='flex flex-row w-[100%] justify-between '>
                <Form className="d-none d-md-flex mx-4 w-[40%]" onSubmit={(e) => e.preventDefault()}>
                    <InputGroup>
                        <InputGroup.Text
                            className="bg-white border-0"
                            onClick={handleSearchClick}
                            style={{ cursor: 'pointer' }}
                        >
                            {isSearching ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                <FaSearch />
                            )}
                        </InputGroup.Text>
                        <FormControl
                            ref={searchRef} // Reference to the search input
                            type="search"
                            placeholder="Search Applications"
                            value={searchQuery}
                            className="border-0 focus:!border-0"
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onFocus={() => setShowSearchResults(true)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault(); // Prevent form submission
                                    handleSearchClick(); // Trigger search on Enter
                                }
                            }}
                        />
                    </InputGroup>

                    {/* Search Results Dropdown */}
                    {showSearchResults && (
                        <div
                            ref={searchResultsRef} // Reference to the search results container
                            className="absolute bg-white shadow-lg rounded-lg mt-1 p-3 w-[60%] max-h-64 overflow-y-auto top-16"
                            style={{ zIndex: 1000 }}
                        >
                            {filteredApplications.length > 0 ? (
                                <Table hover responsive className="table-auto w-full auto-height client-table">
                                    <thead>
                                        <tr>
                                            <th className="text-left px-2 py-1">App ID</th>
                                            <th className="text-left px-2 py-1">Finance Type</th>
                                            <th className="text-left px-2 py-1">Amount</th>
                                            <th className="text-left px-2 py-1">Date Submitted</th>
                                            <th className="text-left px-2 py-1">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredApplications.map((app) => (
                                            <tr
                                                key={app.application_id}
                                                className="cursor-pointer hover:bg-gray-200"
                                                onClick={() => handleApplicationClick(app.application_id)}
                                            >
                                                <td className="px-2 ">{app.application_id}</td>
                                                <td className="px-2 ">{app.finance_type.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</td>
                                                <td className="px-2 ">{app.amount_required}</td>
                                                <td>{new Date(app.date_submitted).toLocaleDateString()}</td>
                                                <td>
                                                    <span
                                                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(app.application_status)}`}
                                                    >
                                                        {app.application_status.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <div className="text-center text-gray-500 py-2">
                                    No results found
                                </div>
                            )}
                        </div>
                    )}
                </Form>

                <div className="d-flex align-items-center">
                    {/* Notification Icon */}
                    <Dropdown show={showDropdown} onToggle={() => setShowDropdown(!showDropdown)}>
                        <Dropdown.Toggle
                            variant="link"
                            className="!flex text-white me-3 -mb-7 p-0 position-relative border-0 bg-transparent shadow-none hover:!bg-transparent"
                            id="notification-dropdown"
                            style={{ boxShadow: 'none' }}
                        >
                            <FaBell size={20} />
                            <span className="absolute bottom-10 left-4 bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                                {notifications.length > 0 ? notifications.length : '0'}
                            </span>
                        </Dropdown.Toggle>

                        {/* Notification Dropdown Menu with Chat-like Layout */}
                        <Dropdown.Menu
                            align="end"
                            className="dropdown-menu-end"
                            style={{
                                maxHeight: '500px',
                                width: '350px',
                                overflowY: 'auto',
                                padding: '10px',
                            }}
                        >
                            {loading ? (
                                <Dropdown.Item>Loading notifications...</Dropdown.Item>
                            ) : error ? (
                                <Dropdown.Item>{error}</Dropdown.Item>
                            ) : notifications.length === 0 ? (
                                <Dropdown.Item>No notifications available.</Dropdown.Item>
                            ) : (
                                notifications.map((notification, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleNotificationClick(notification)}
                                        className={`mb-3 p-3 rounded-lg text-sm shadow-md cursor-pointer border-l-4 ${notification.ask_additional_document
                                                ? 'border-yellow-400 bg-yellow-50'
                                                : 'border-blue-400 bg-blue-50'
                                            }`}
                                        style={{
                                            display: 'block',
                                            width: '100%',
                                            background: '#f9f9f9',
                                        }}
                                    >
                                        <div className="flex justify-between mb-1">
                                            <div className="font-bold text-sm text-gray-700">
                                                Application ID: <span className="text-blue-600">{notification.application_id}</span>
                                            </div>
                                            <div className="text-xs text-gray-500">
                                                {new Date(notification.sent_at).toLocaleString()}
                                            </div>
                                        </div>
                                        <div className="mt-1 text-gray-800">
                                            <span className="font-semibold">Message:</span>
                                            <p>{notification.message}</p>
                                        </div>
                                        {notification.document_label && (
                                            <div className="mt-1">
                                                <span className="font-semibold">Document:</span>
                                                <p>{notification.document_label}</p>
                                            </div>
                                        )}
                                        {notification.ask_additional_document && (
                                            <div className="mt-1">
                                                <span className="font-semibold text-yellow-700">Request for Additional Document</span>
                                            </div>
                                        )}
                                    </div>
                                ))
                            )}
                        </Dropdown.Menu>
                    </Dropdown>

                    <img
                        src={ProfileUrl} // Replace this with actual profile picture source
                        alt="Profile"
                        className="rounded-circle cursor-pointer"
                        style={{ width: '35px', height: '35px' }}
                        onClick={() => navigate('/dashboard/settings')}
                    />

                    <Button
                        variant="outline-light"
                        className="d-md-none ms-3"
                        onClick={toggleSidebar}
                    >
                        <FaBars />
                    </Button>
                </div>
            </div>
        </Navbar>
    );
};

export default NavbarComponent;
