import React, { useContext, useEffect, useState } from 'react';
import { BannerContext } from '../../context/bannerContext';
import { ProgressContext } from '../../context/ProgressContext';
import { Button, Form, ProgressBar, Spinner, Toast, ToastContainer } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import uploadlogo from '../../assets/cloud_upload logo.png';  
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';

const Step5 = () => {
    const { setBannerState } = useContext(BannerContext);
    const { setProgress } = useContext(ProgressContext);
    const navigate = useNavigate();

    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const applicationId = user ? user.application_id : null;

    const [labels, setLabels] = useState([]);
    const [documents, setDocuments] = useState({});
    const [progress, SetProgress] = useState({});
    const [loading, setLoading] = useState(false);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        setBannerState({
            title: 'Document Upload',
            information: 'Upload the required documents based on your funding needs.',
        });
    }, [setBannerState]);

    useEffect(() => {
        setProgress({
            text: "2 more steps to go. Almost there!",
            percentage: 65.01 
        });
    }, []);

    useEffect(() => {
        const fetchLabelsAndDocuments = async () => {
            try {
                const labelResponse = await axios.get(`${url}/api/applications/get-labels/${applicationId}/`);
                if (labelResponse.status === 200) {
                    setLabels(labelResponse.data);
                }

                const documentResponse = await axios.get(`${url}/api/applications/${applicationId}/documents/`);
                if (documentResponse.status === 200) {
                    const fetchedDocuments = documentResponse.data.reduce((acc, doc) => {
                        if (!acc[doc.label]) {
                            acc[doc.label] = [];
                        }
                        acc[doc.label].push(doc);
                        return acc;
                    }, {});
                    setDocuments(fetchedDocuments);
                }
            } catch (error) {
                console.error('Error fetching labels or documents:', error);
                setToast({ show: true, message: 'Failed to load labels or documents.', type: 'error' });
            }
        };

        fetchLabelsAndDocuments();
    }, [applicationId]);

    const allowedFileTypes = [
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const handleFileChange = async (event, labelId) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(file => {
            if (file.size > 10 * 1024 * 1024) {
                setToast({ show: true, message: 'File size must be less than 10MB', type: 'error' });
                return false;
            }
            if (!allowedFileTypes.includes(file.type)) {
                setToast({ show: true, message: 'Invalid file type. Allowed types: PDF, DOC, DOCX, CSV, XLSX', type: 'error' });
                return false;
            }
            return true;
        });

        for (const file of validFiles) {
            await uploadFile(file, labelId);
        }
    };

    const uploadFile = async (file, labelId) => {
        const formData = new FormData();
        formData.append('label_id', labelId);
        formData.append('files', file);

        try {
            setLoading(true);
            const response = await axios.post(`${url}/api/applications/${applicationId}/documents/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    SetProgress(prev => ({ ...prev, [labelId]: percentCompleted }));
                },
            });

            if (response.status === 201) {
                setToast({ show: true, message: 'Document uploaded successfully.', type: 'success' });

                const newDoc = response.data[0]; 
                setDocuments(prev => {
                    const newDocuments = { ...prev };
                    if (!newDocuments[labelId]) {
                        newDocuments[labelId] = [];
                    }

                    const existingDoc = newDocuments[labelId].find(doc => doc.file.split('/').pop() === newDoc.file.split('/').pop());
                    if (!existingDoc) {
                        newDocuments[labelId].push(newDoc);
                    }

                    return newDocuments;
                });
            }
        } catch (error) {
            setToast({ show: true, message: 'Failed to upload document.', type: 'error' });
        } finally {
            setLoading(false);
            SetProgress(prev => ({ ...prev, [labelId]: 0 })); 
        }
    };

    const handleDelete = async (documentId, labelId, event) => {
        event.preventDefault();

        try {
            await axios.delete(`${url}/api/applications/${applicationId}/documents/`, {
                data: { document_id: documentId }, 
            });

            setDocuments(prev => {
                const newDocuments = { ...prev };
                newDocuments[labelId] = newDocuments[labelId].filter(doc => doc.id !== documentId);
                return newDocuments;
            });

            setToast({ show: true, message: 'Document deleted successfully.', type: 'success' });
        } catch (error) {
            console.error('Failed to delete document:', error);
            setToast({ show: true, message: 'Failed to delete document.', type: 'error' });
        }
    };

    const validateRequiredFields = () => {
        for (const label of labels) {
            if (label.required && (!documents[label.id] || documents[label.id].length === 0)) {
                setToast({ show: true, message: `Please upload a document for: ${label.label}`, type: 'error' });
                return false;
            }
        }
        return true;
    };

    const handleNextStep = () => {
        if (validateRequiredFields()) {
            navigate('/steps/step6');
        }
    };

    return (
        <div>
            <ToastContainer position="top-end" className="p-3">
                <Toast onClose={() => setToast({ ...toast, show: false })} show={toast.show} bg={toast.type} delay={3000} autohide>
                    <Toast.Body className='!text-white font-medium'>{toast.message}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Form className="pt-[8%]">
                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%]">Upload Documents</h2>

                <div className='max-h-[40vh] overflow-y-auto custom-scrollbar pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>
                        {labels.map(label => (
                            <div key={label.id}>
                                <Form.Group controlId={`form${label.id}`}>
                                    <Form.Label className="text-lg font-semibold">
                                        {label.label} {label.required && <span className="text-red-500">*</span>}
                                    </Form.Label>

                                    <div className="border-2 border-dashed border-gray-400 rounded-lg flex items-center justify-around gap-3 px-2 py-3">
                                        <div className="flex items-center gap-3">
                                            <div className="text-gray-500">
                                                <img src={uploadlogo} width={30} height={30} alt="upload logo" />
                                            </div>
                                            <div>
                                                <p className="text-sm text-gray-500 mb-0">Select a file or drag and drop here</p>
                                                <p className="text-xs text-gray-400">PDF, DOC, DOCX, XLSX, CSV, file size no more than 10MB</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                type="file"
                                                id={`input${label.id}`}
                                                onChange={e => handleFileChange(e, label.id)}
                                                accept=".pdf,.doc,.docx,.xlsx,.csv"
                                                className="hidden"
                                                multiple
                                            />
                                            <label
                                                htmlFor={`input${label.id}`}
                                                className="text-sm bg-[#FBFDFE] text-[#B38D2E] py-2 px-4 rounded cursor-pointer hover:bg-[#B38D2E] border-1 border-[#B38D2E] hover:text-white hover:font-semibold transition duration-300"
                                            >
                                                Upload
                                            </label>
                                        </div>
                                    </div>

                                    {/* Display uploaded documents */}
                                    {documents[label.id] && documents[label.id].length > 0 && (
                                        <div className="mt-2">
                                            <h5 className="text-lg font-semibold">Uploaded Documents:</h5>
                                            <ul className="list-disc pl-5 space-y-3">
                                                {documents[label.id].map(doc => (
                                                    <li key={doc.id} className="flex justify-between items-center">
                                                        <a href={doc.file} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">{doc.file.split('/').pop()}</a>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={e => handleDelete(doc.id, label.id, e)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    {/* Show progress bar if there's progress */}
                                    {progress[label.id] > 0 && (
                                        <div className="mt-2">
                                            <ProgressBar now={progress[label.id]} label={`${progress[label.id]}%`} />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        ))}
                    </div>
                </div>
                <div
                    className="absolute bottom-[22%] left-0 right-0 h-8 "
                    style={{
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.14) 16%, rgba(102, 102, 102, 0) 100%)',
                        transform: 'scaleY(1)', // This inverts the gradient vertically
                    }}
                ></div>
                <div className='flex gap-2 pt-[10%] pb-[2%] px-[10%]'>
                    <button
                        className="p-2 px-3 h-12 hover:text-white hover:bg-[#f1b00c] transition-colors ease-in flex items-center justify-center border-[1px] rounded-md border-[#111111]"
                        onClick={() => (navigate("/steps/step4"))}
                        disabled={loading}
                    >
                        <FaArrowLeft /> {/* Arrow Icon */}
                    </button>
                    <Button
                        variant="warning"
                        className="w-full h-10 text-white rounded-md"
                        onClick={handleNextStep}
                        disabled={loading}
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Continue'}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Step5;
