import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Spinner } from 'react-bootstrap';
import '../../styles/EmailVerification.css'; // Create this CSS file for animations
import { url } from '../../utils/networkconfig';

const EmailVerification = () => {
  const { token } = useParams(); // Extract the token from the URL
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true); // New state for spinner
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const response = await axios.get(`${url}/api/verify-email/${token}/`);

        if (response.status === 200) {
          setLoading(false);
          navigate('/login'); // Directly navigate to login on success
        }
      } catch (error) {
        setError(true);
        setMessage('Failed to verify email. The token may be invalid or expired.');
        setLoading(false); // Stop loading on error
      }
    };

    verifyEmail(); // Call the verification function
  }, [token, navigate]); // Add dependencies to avoid rerunning multiple times

  const handleSignupRedirect = () => {
    navigate('/');
  };

  return (
    <div className="email-verification-container">
      {loading ? (
        // Show spinner while loading
        <div className="spinner-container">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Verifying...</span>
          </Spinner>
          <p>Verifying your email...</p>
        </div>
      ) : error ? (
        // Show error message if verification failed
        <div className="message-container fade-in">
          <p className="error-message">{message}</p>
          <Button onClick={handleSignupRedirect}>Sign Up Again</Button>
        </div>
      ) : null}
    </div>
  );
};

export default EmailVerification;
