import React, { useState } from 'react';
import { Button, Col, ProgressBar, Row } from 'react-bootstrap';
import axios from 'axios';
import fileIcon from '../assets/fileIcon.svg';
import UploadDocumentModal from './UploadAdditionalDocumentsModal';
import { url } from '../utils/networkconfig';

const UserDocumentsList = ({ documents, name, email, application_id }) => {
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [docStatus, setDocStatus] = useState(
        documents.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.status }), {})
    );
    const [progress, setProgress] = useState({});
    const [docFiles, setDocFiles] = useState(
        documents.reduce((acc, doc) => ({
            ...acc,
            [doc.id]: { file: doc.file, fileName: doc.file.split('/').pop() }
        }), {})
    );

    // Function to handle file upload and status update
    const handleFileUpload = async (docId, file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('status', 'under_review'); // Set status to under_review after upload

        try {
            const response = await axios.put(
                `${url}/api/document/${docId}/update/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress((prevProgress) => ({
                            ...prevProgress,
                            [docId]: percentCompleted
                        }));
                    }
                }
            );

            if (response.status === 200) {
                const updatedDoc = response.data;
                const newFileName = updatedDoc.file.split('/').pop(); // Get the new file name

                // Update document status, file URL, and file name in the state
                setDocStatus((prevState) => ({
                    ...prevState,
                    [docId]: 'under_review'
                }));
                setDocFiles((prevFiles) => ({
                    ...prevFiles,
                    [docId]: { file: updatedDoc.file, fileName: newFileName }
                }));

                // Remove progress bar after 2 seconds
                setTimeout(() => {
                    setProgress((prevProgress) => ({
                        ...prevProgress,
                        [docId]: 0
                    }));
                }, 2000);
            }
        } catch (error) {
            console.error('Error uploading document:', error);
        }
    };

    // Function to add the new document to the list after successful upload
    const addDocumentToState = (newDocument) => {
        // Update document status, file URL, and file name in the state
        setDocStatus((prevState) => ({
            ...prevState,
            [newDocument.id]: 'under_review'
        }));
        setDocFiles((prevDocs) => ({
            ...prevDocs,
            [newDocument.id]: { file: newDocument.file, fileName: newDocument.file.split('/').pop() },
        }));
    };

    const handleUploadModalClose = () => {
        setShowUploadModal(false);
    };

    const renderDocumentRow = (doc) => {
        const status = docStatus[doc.id]; // Get the status for this document
        const docFile = docFiles[doc.id]?.file; // Get the updated file URL
        const fileName = docFiles[doc.id]?.fileName; // Get the updated file name

        // Determine if action buttons should be disabled or hidden
        const isUnderReview = status === 'under_review';
        const isAccepted = status === 'accepted';
        const isRejected = status === 'rejected';
        const isReminderPending = status === 'reminder_pending';

        return (
            <Row key={doc.id} className="mb-3 border-b pb-2">
                <Col md={6}>
                    <div className="flex items-center">
                        <div>
                            <img src={fileIcon} alt="document-icon" className="mr-2 w-6 h-6" />
                        </div>
                        <div className="flex-col">
                            <div>
                                <a href={docFile} target="_blank" rel="noopener noreferrer" className="text-sm !no-underline">
                                    {fileName} <span className="text-sm text-gray-500">(5.7 MB)</span> {/* Update size as needed */}
                                </a>
                            </div>
                            <div>
                                <a href={docFile} download className="text-sm Barlow">Download</a>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6} className="text-start">
                    {isUnderReview ? (
                        <div className="flex gap-3 justify-start">
                            <p className="font-bold text-gray-500">Under Review</p>
                            <label className="underline text-orange-500 font-semibold cursor-pointer">
                                Upload Again
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(doc.id, e.target.files[0])} // Handle file upload
                                />
                            </label>
                        </div>

                    ) : isRejected ? (
                        <div className="flex gap-3 justify-start">
                            <p className="font-bold text-red-500">Rejected</p>
                            <label className="underline text-orange-500 font-semibold cursor-pointer">
                                Upload New
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(doc.id, e.target.files[0])} // Handle file upload
                                />
                            </label>
                        </div>
                    ) : isReminderPending ? (
                        <div className="flex gap-3 justify-start">
                            <p className="font-bold text-orange-500">Reminder Pending</p>
                            <label className="underline text-orange-500 font-semibold cursor-pointer">
                                Upload New
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(doc.id, e.target.files[0])} // Handle file upload
                                />
                            </label>
                        </div>
                    ) : isAccepted ? (
                        <div className="flex gap-3 justify-start">
                            <p className="font-bold text-green-500">Accepted</p>
                            <label className="underline text-orange-500 font-semibold cursor-pointer">
                                Upload Again
                                <input
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileUpload(doc.id, e.target.files[0])} // Handle file upload
                                />
                            </label>
                        </div>
                    ) : null}

                    {/* Show progress bar if there's progress */}
                    {progress[doc.id] > 0 && (
                        <div className="mt-2">
                            <ProgressBar now={progress[doc.id]} label={`${progress[doc.id]}%`} />
                        </div>
                    )}
                </Col>
            </Row>
        );
    };

    const groupedDocuments = documents.reduce((acc, doc) => {
        (acc[doc.label] = acc[doc.label] || []).push(doc);
        return acc;
    }, {});

    return (
        <>
            <div className="border rounded-md p-4 bg-white">
                <div className="flex justify-between items-center mb-4">
                    <h4 className="text-lg font-semibold text-[#04394F]">Documents</h4>
                    <Button
                        variant="primary"
                        onClick={() => setShowUploadModal(true)}
                    >
                        Upload Additional Document
                    </Button>
                </div>
                {Object.entries(groupedDocuments).map(([label, docs]) => (
                    <div key={label}>
                        <h5 className="font-semibold">{label}</h5>
                        {docs.map(renderDocumentRow)}
                    </div>
                ))}
            </div>
            <UploadDocumentModal
                show={showUploadModal}
                handleClose={handleUploadModalClose}
                applicationId={application_id}
                addDocumentToState={addDocumentToState}
            />
        </>
    );
};

export default UserDocumentsList;
