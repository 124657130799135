import React, { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { Spinner, Table, Button, Col, Row, Form } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/UserTable.css';
import CardComponent from '../../custom_components/card_component';
import docsLogo from '../../assets/docs.svg';
import docsLogoChecked from '../../assets/docs_check.svg';
import docsLogoLoading from '../../assets/docs_loading.svg';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';


const ProgressBar = ({ width }) => {
    return (
        <div className='flex justify-center items-center'>
            <div className='w-full rounded-lg h-1 bg-[#04394F] bg-opacity-15 -mt-4'>
                <div className={`h-1 rounded-lg bg-[#04394F]`} style={{ width: `${width}%` }}></div>
            </div>
            <p>{width}%</p>
        </div>
    );
};

const UserMyApplications = () => {
    const [applications, setApplications] = useState([]);
    const [counts, setCounts] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const user_id = user ? user.user_id : null;

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/api/user/myapplications/${user_id}/`);
                setApplications(response.data.applications);
                setCounts(response.data.counts);
            } catch (error) {
                setError('Error fetching data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'submitted':
                return 'bg-gray-100 text-gray-800';
            case 'in_progress':
                return 'bg-yellow-100 text-yellow-800';
            case 'under_review':
                return 'bg-blue-100 text-blue-800';
            case 'approved':
                return 'bg-green-100 text-green-800';
            case 'rejected':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const handleSendReminder = (client) => {
        // Add logic for sending reminder
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Spinner animation="border" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div className="px-6 py-4 mx-auto">
            {/* Welcome Header */}
            <div className='flex flex-row justify-between'>
                <div className='text-[#04394F] text-3xl font-bold'>My Applications</div>
                <Button onClick={()=>(navigate('/dashboard/NewApplication'))}>New Application</Button>
            </div>

            {/* Cards Section */}
            <div className="container mx-auto mt-8 border-1 border-solid border-[rgba(0, 0, 0, 0.25)] py-3">
                <div className="rounded-sm">
                    <div className='flex-grow flex-row flex justify-between items-center'>
                        <p className='font-semibold text-2xl -mb-1'>Applications Summary</p>
                    </div>

                    <div className='mt-4'>
                        <Row>
                            <Col>
                                <CardComponent
                                    iconSrc={docsLogo}
                                    iconBgColor="#04394F"
                                    heading="Active Applications"
                                    count={counts.in_progress || 0}
                                    subtext="Number of applications in progress."
                                />
                            </Col>
                            <Col>
                                <CardComponent
                                    iconSrc={docsLogoChecked}
                                    iconBgColor="#00B67A"
                                    heading="Completed Applications"
                                    count={counts.completed_or_approved || 0}
                                    subtext="Number of completed applications."
                                />
                            </Col>
                            <Col>
                                <CardComponent
                                    iconSrc={docsLogoLoading}
                                    iconBgColor="#B38D2E"
                                    heading="Pending Tasks"
                                    count={counts.rejected_documents_or_reminder_pending || 0}
                                    subtext="Pending tasks in active applications."
                                />
                            </Col>
                            <Col>
                                <CardComponent
                                    iconSrc={docsLogo}
                                    iconBgColor="#C5221F"
                                    heading="Rejected Applications"
                                    count={counts.rejected_applications || 0}
                                    subtext="These are the rejected applications."
                                />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/* Applications Table */}
            <div className="container mx-auto mt-8 border-1 border-solid border-[rgba(0, 0, 0, 0.25)] py-3">
                <div className='flex-grow flex-row flex justify-between items-center'>
                    <p className='font-semibold text-2xl -mb-1'>Your Applications</p>
                </div>

                <div className="table-responsive mt-4">
                    {applications.length === 0 ? (
                        <p className="text-center p-4">No applications are there.</p>
                    ) : (
                        <Table responsive className="min-w-full auto-height client-table">
                            <thead>
                                <tr>
                                    <th>Application ID</th>
                                    <th>Application Type</th>
                                    <th>Amount Requested</th>
                                    <th>Date Submitted</th>
                                    <th>Application Status</th>
                                    <th>Progress</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications.map((application, index) => (
                                    <tr key={index}>
                                        <td>{application.application_id}</td>
                                        <td>{application.finance_type.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</td>
                                        <td>{application.amount_required}</td>
                                        <td>{new Date(application.date_submitted).toLocaleDateString()}</td>
                                        <td>
                                            <span
                                                className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(application.application_status)}`}
                                            >
                                                {application.application_status.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                            </span>
                                        </td>
                                        <td>
                                            <ProgressBar width={application.review_percentage} />
                                        </td>
                                        <td>
                                            <Menu as="div" className="relative inline-block text-left">
                                                <Menu.Button>
                                                    <FaEllipsisV />
                                                </Menu.Button>

                                                <Menu.Items className="origin-top-right absolute right-3 bottom-[-10px] mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                                    <div className="py-1 Barlow">
                                                        <Menu.Item className="border-b-[0.5px] border-solid border-b-[rgba(0, 0, 0, 0.25)]">
                                                            {({ active }) => (
                                                                <a
                                                                    href="#"
                                                                    onClick={() => navigate(`/dashboard/application-details/${application.application_id}/`)}
                                                                    className={`block px-4 py-2 text-xs !text-black !no-underline ${active ? 'bg-gray-100' : ''}`}
                                                                >
                                                                    View Details
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                        {/* <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    href="#"
                                                                    className={`block px-4 py-2 text-xs ${active ? 'bg-gray-100' : ''}`}
                                                                    onClick={() => handleSendReminder(application)}
                                                                >
                                                                    Send Reminder
                                                                </a>
                                                            )}
                                                        </Menu.Item> */}
                                                    </div>
                                                </Menu.Items>
                                            </Menu>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserMyApplications;
