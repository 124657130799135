import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import axios from 'axios';
import { Spinner, Alert } from 'react-bootstrap';
import { url } from '../../utils/networkconfig';

// Register necessary Chart.js components
Chart.register(...registerables, ChartDataLabels);

const RevenueGraph = () => {
    const [revenueData, setRevenueData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch the data from the API
    useEffect(() => {
        const fetchRevenueData = async () => {
            try {
                setLoading(true);  // Show loading spinner
                const response = await axios.get(`${url}/api/approved-funding/`);
                setRevenueData(response.data);
            } catch (err) {
                setError('Failed to fetch revenue data.');
            } finally {
                setLoading(false);
            }
        };

        fetchRevenueData();
    }, []);

    // Show spinner while loading
    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    // Show error message if failed to fetch data
    if (error) {
        return (
            <div className="bg-white p-4 rounded-lg">
                <Alert variant="danger">
                    {error}
                </Alert>
            </div>
        );
    }

    // If no data available
    if (!revenueData || revenueData.months.length === 0) {
        return (
            <div className="bg-white p-4 rounded-lg">
                <h3 className="text-lg font-bold mb-2">Amount of Funding</h3>
                <p className="text-gray-500">No revenue data available.</p>
            </div>
        );
    }

    // Prepare chart data
    const chartData = {
        labels: revenueData.months,
        datasets: [
            {
                label: 'Total Funding',
                data: revenueData.total_funding,
                backgroundColor: '#B38D2E',
                borderColor: '#8e6c22',
                hoverBackgroundColor: '#FFCE56',
                borderWidth: 2,
                hoverBorderColor: '#F57C00',
            },
        ],
    };

    // Customize chart options
    const chartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 45,  // Angle x-axis labels
                    minRotation: 0,
                    font: {
                        size: 12,
                    },
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    color: '#EAEAEA',
                },
                ticks: {
                    callback: function (value) {
                        return '$' + value.toLocaleString();  // Format y-axis as currency
                    },
                    font: {
                        size: 12,
                    },
                },
            },
        },
        plugins: {
            tooltip: {
                enabled: true,
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                titleFont: {
                    size: 14,
                    weight: 'bold',
                },
                callbacks: {
                    label: function (tooltipItem) {
                        return 'Funding: $' + tooltipItem.raw.toLocaleString();  // Tooltip formatting
                    },
                },
            },
            datalabels: {
                // Display only on hover
                display: (context) => context.hovered,
                anchor: 'end',
                align: 'bottom',
                color: 'white',
                font: {
                    size: 12,
                },
                formatter: function (value) {
                    return '$' + value.toLocaleString();  // Show labels on hover
                },
            },
        },
        hover: {
            mode: 'index',
            intersect: false,
            animationDuration: 300,
        },
    };

    return (
        <div className="bg-white px-4 pt-4 pb-12 rounded-lg border" style={{ height: '100%' }}>
            <h3 className="text-lg font-bold mb-2">Amount of Funding</h3>
            <Bar data={chartData} options={chartOptions} height={400} />
        </div>
    );
};

export default RevenueGraph;
