import React, { useContext } from 'react';
import comments from "../../assets/Mask group.png";
import Logo from "../../assets/Logo.png";
import Dots from "../../assets/dots.png"
import Dots_small from '../../assets/dots small.png'
import { BannerContext, useBanner } from '../../context/bannerContext';

const AdminBanner = () => {
    const { title, information } = useContext(BannerContext);

    return (
        <div className="bg-[#B38D2E] text-white p-5 flex flex-col justify-center items-center h-full Montserrat text-center relative overflow-hidden">
            {/* Logo at the top right */}
            <img src={Logo} alt="Logo" className="absolute top-5 left-[10%] h-[15%]" />
            <img src={Dots_small} alt="" className='absolute top-0 -right-12'></img>


            {/* Centered content */}
            <div className="flex flex-col items-center justify-center flex-grow mb-[35%] text-left">
                <h1 className="text-3xl font-bold">{title}</h1>
                <p className="text-left max-w-md mt-4 Barlow font-normal text-base text-[#04394F]">
                    {information}
                </p>
            </div>

            {/* Comments or image at the maximum bottom */}
            <div className="absolute -bottom-[5%] w-full flex justify-center">
                <img src={comments} alt="comments" className="w-full" />
            </div>
            <img src={Dots} alt="" className='absolute bottom-0 left-0 w-[35%]'></img>

        </div>
    );
};

export default AdminBanner;
