import React from 'react';
import { Card } from 'react-bootstrap';

const ApplicationStatus = ({ status, history }) => {
  // Function to determine the background color based on status
  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return 'bg-[#00B67A] text-white'; // #00B67A
      case 'in_progress':
        return 'bg-[#E8DDC0] text-black'; // #E8DDC0
      case 'under_review':
        return 'bg-[#E3E3E3] text-black'; // #E3E3E3
      case 'submitted':
        return 'bg-[#00B67A]'; // #E3E3E3
      case 'approved':
        return 'bg-[#00B67A]'; // #E3E3E3
      case 'rejected':
        return 'bg-[#C5221F]'; // #E3E3E3
      default:
        return 'bg-[#E3E3E3] text-black'; // Fallback color
    }
  };

  const formatStatus = (status) => {
    return status
      .split('_') // Split the status by underscore
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join them back with spaces
  };

  return (
    <Card className="!bg-[#F6F6F6] !h-full flex flex-col">
      <Card.Header className='!border-0 !bg-[#F6F6F6]'>
        <p className='font-bold text-xl'>Application Status</p>
        <p className='text-sm Barlow -mt-3'>Your current application</p>
        <div className='h-[1px] border-b border-[rgba(0, 0, 0, 0.4)]'></div>
      </Card.Header>
      <Card.Body className='text-sm text-black font-medium mt-2 flex-grow'>
        {history.map((entry, index) => (
          <div key={index} className={`flex-1 flex justify-between`}>
            <p>{entry.financeType}</p>
            <p className={`Barlow text-xs ${getStatusColor(entry.status)} px-2 py-1 rounded-xl`}>
              {formatStatus(entry.status)}
            </p>
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

export default ApplicationStatus;
