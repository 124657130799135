import React from 'react';
import { Card } from 'react-bootstrap';

const ReferralCommission = ({ commission }) => {
  return (
    <Card className="!bg-[#B38D2E] !text-white !border-0 h-full flex flex-col">
      <Card.Header className='!border-0 !bg-[#B38D2E] pt-2'>
        <p className='font-bold text-xl'>Referral Commission</p>
        <div className='h-[1px] border-b border-[rgba(0, 0, 0, 0.4)]'></div>
      </Card.Header>
      <Card.Body className='text-sm font-medium flex-grow'>
        <div className={`flex-1 flex justify-between px-2 py-1`}>
          <p>Total number of referrals</p>
          <p className={`text-base rounded-xl font-bold`}>{commission.total_referrals}</p>
        </div>
        <div className={`flex-1 flex justify-between px-2 py-1`}>
          <p>Total Amount Paid</p>
          <p className={`text-base  rounded-xl font-bold`}>£ {commission.total_amount_paid}</p>
        </div>
        <div className={`flex-1 flex justify-between px-2 py-1`}>
          <p>Under Process Referrals</p>
          <p className={`text-base rounded-xl font-bold`}>{commission.under_process_referrals}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ReferralCommission;
