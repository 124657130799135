// src/contexts/BannerContext.js
import React, { createContext, useState } from 'react';

export const BannerContext = createContext();

export const BannerProvider = ({ children }) => {
    const [bannerState, setBannerState] = useState({
        title: '',
        information: '',
    });

    return (
        <BannerContext.Provider value={{ ...bannerState, setBannerState }}>
            {children}
        </BannerContext.Provider>
    );
};
