import React, { createContext, useState } from 'react';

// Create context
export const DocumentUploadContext = createContext();

// Provider component
export const DocumentUploadProvider = ({ children }) => {
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);

  const triggerDocumentUpdate = () => {
    setIsDocumentUploaded((prevState) => !prevState); // Toggle the state to trigger the useEffect in parent
  };

  return (
    <DocumentUploadContext.Provider value={{ isDocumentUploaded, triggerDocumentUpdate }}>
      {children}
    </DocumentUploadContext.Provider>
  );
};
