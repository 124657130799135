import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Spinner, Toast, ToastContainer, Container, Row, Col } from 'react-bootstrap';
import { ProgressContext } from '../../context/ProgressContext';
import { BannerContext } from '../../context/bannerContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FaCheckCircle } from 'react-icons/fa';
import CircleTick from '../../assets/charm_circle-tick.svg';

const ThankYou = () => {
    const { setBannerState } = useContext(BannerContext);
    const { setProgress } = useContext(ProgressContext);

    useEffect(() => {
        setProgress({
            text:"All done! Thank you",
            percentage: 100 // Update percentage based on the step
          });

        setBannerState({
            title: 'What Happens Next?'
        });
    }, [setBannerState, setProgress]);

    const navigate = useNavigate();

    return (
        <div className="pt-[8%] px-[12%] w-[100%] mt-3">
            <Row className="text-left ">
                <Col>
                    {/* Checkmark Icon */}
                    <img src={CircleTick} alt='CircleTick' className='w-14 h-14 -ml-3 mb-2'></img>
                    {/* Title */}
                    <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F]">
                        Thank You for Your Submission!
                    </h2>

                    {/* Description */}
                    <p className="text-black mb-3 Barlow">
                        Thank you for submitting your application! We're thrilled to review
                        it and ensure every detail is thoroughly assessed. Our dedicated
                        team is on it, and you can expect a response within the next{' '}
                        <span className="font-semibold">3 hours</span>. We can't wait to
                        connect with you.
                    </p>

                    {/* Additional Info */}
                    <p className="text-black mb-3 Barlow">
                        You can continue to your dashboard now and monitor your application
                        status. We will notify you by email whenever there is an update.
                    </p>
                    <div className='text-center'>
                        {/* Button */}
                        <Button
                            variant="warning"
                            className="text-white font-semibold py-2 px-4 rounded-md w-full"
                            onClick={() => navigate('/dashboard')}
                        >
                            Go to Dashboard
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};


export default ThankYou;
