import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const useAuth = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    if (user) {
      if (user.steps_completed) {
        navigate('/dashboard'); // Navigate to dashboard if steps are completed
      } else {
        navigate('/steps'); // Navigate to steps if steps are not completed
      }
    }
  }, [navigate]);
};

export default useAuth;
