import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, InputGroup, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash, FaCheckCircle } from 'react-icons/fa';
import { BannerContext } from '../../context/bannerContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import google_logo from "../../assets/google_logo.svg";
import microsoft_logo from '../../assets/microsoft_logo.svg';
import Cookies from 'js-cookie';  // Import js-cookie
import { url } from '../../utils/networkconfig';


const SignupForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validation, setValidation] = useState({
    hasLowercase: false,
    hasUppercase: false,
    hasNumber: false,
    hasSpecialChar: false,
    isMinLength: false,
  });
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate();
  const { setBannerState } = useContext(BannerContext);
  const location = useLocation();  // Get the current location to extract query params


  useEffect(() => {
    setBannerState({
      title: 'Your Business Finance Always Comes First',
      information: 'Pinnacle Business Finance is a commercial finance brokerage based in Bristol. We are an unrivalled team of commercial finance experts.',
    });
  }, [setBannerState]);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Validate password conditions
    setValidation({
      hasLowercase: /[a-z]/.test(value),
      hasUppercase: /[A-Z]/.test(value),
      hasNumber: /\d/.test(value),
      hasSpecialChar: /[!@#$%^&*]/.test(value),
      isMinLength: value.length >= 8,
    });
  };


  // Modify the handleSubmit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start the spinner and disable button

    // Clear previous messages
    setShowErrorToast(false);
    setShowSuccessToast(false);

    // Basic validations
    if (password !== confirmPassword) {
      setToastMessage('Passwords do not match.');
      setShowErrorToast(true);
      setIsSubmitting(false); // Stop the spinner and enable button
      return;
    }

    if (!validation.hasLowercase || !validation.hasUppercase || !validation.hasNumber || !validation.hasSpecialChar || !validation.isMinLength) {
      setToastMessage('Please meet all password requirements.');
      setShowErrorToast(true);
      setIsSubmitting(false); // Stop the spinner and enable button
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    let referralCode = queryParams.get('ref');
    if (referralCode) {
      referralCode = referralCode.replace(/\/$/, '');
    }


    try {
      // Send signup request
      const response = await axios.post(`${url}/api/signup/`, {
        email: email,
        password: password,
        ...(referralCode && { referral_code: referralCode })
      });

      // Success
      setToastMessage('Signup successful. Please check your email to verify your account.');
      setShowSuccessToast(true);
      Cookies.set('email', email, { expires: 7 }); // Expires in 7 days
      setTimeout(() => {
        navigate('/checkEmail');
      }, 1000);
    } catch (error) {
      // Error handling
      if (error.response) {
        if (error.response.status === 400) {
          const errorData = error.response.data;
          console.log(errorData);
          if (errorData.email && errorData.email[0] === "user with this email already exists.") {
            setToastMessage("A user with this email already exists.");
          } else if (errorData.password) {
            setToastMessage(errorData.password[0] || 'Invalid password.');
          }
          else if (errorData.error === "Invalid referral code.") {
            setToastMessage('Invalid Referral Code.');
          }

          else {
            setToastMessage('Signup failed. Please check your input and try again.');
          }
        } else {
          setToastMessage(error.response?.data?.detail || 'Signup failed. Please try again.');
        }
      } else {
        setToastMessage('Network error. Please try again later.');
      }
      setShowErrorToast(true);
    } finally {
      setIsSubmitting(false); // Stop the spinner and enable button
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Clear previous messages
  //   setShowErrorToast(false);
  //   setShowSuccessToast(false);

  //   // Basic validations
  //   if (password !== confirmPassword) {
  //     setToastMessage('Passwords do not match.');
  //     setShowErrorToast(true);
  //     return;
  //   }

  //   if (!validation.hasLowercase || !validation.hasUppercase || !validation.hasNumber || !validation.hasSpecialChar || !validation.isMinLength) {
  //     setToastMessage('Please meet all password requirements.');
  //     setShowErrorToast(true);
  //     return;
  //   }
  //     Cookies.set('email', email, { expires: 7 }); // Expires in 7 days, you can modify the expiration time as needed.
  //     setTimeout(() => {
  //       navigate('/checkEmail');
  //     }, 1000);

  // }


  return (
    <>
      {/* Toast Notifications */}
      <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShowErrorToast(false)} show={showErrorToast} bg="danger" delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Error</strong>
          </Toast.Header>
          <Toast.Body className='text-white font-medium'>{toastMessage}</Toast.Body>
        </Toast>

        <Toast onClose={() => setShowSuccessToast(false)} show={showSuccessToast} bg="success" delay={3000} autohide>
          <Toast.Header>
            <strong className="me-auto">Success</strong>
          </Toast.Header>
          <Toast.Body className='text-white font-medium'>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Form className="w-[60%] pt-[4%]" onSubmit={handleSubmit}>
        <h2 className="text-3xl font-bold mb-2 text-center text-[#04394F]">Signup</h2>
        <p className="text-center font-normal text-base Barlow">Please complete the form below to access your portal.</p>

        {/* Email Input */}
        <Form.Group className="mt-2" controlId="formEmail">
          <Form.Label>Email<span className="text-red-500">*</span></Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        {/* Password Input with Visibility Toggle */}
        <Form.Group controlId="formPassword" className="mt-3 relative">
          <Form.Label>Password<span className="text-red-500">*</span></Form.Label>
          <InputGroup>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              className='!rounded-md'
              required
            />
            <InputGroup.Text
              onClick={() => setShowPassword(!showPassword)}
              className="cursor-pointer absolute !bg-transparent !border-0 right-0 top-2 z-50"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </InputGroup.Text>
          </InputGroup>

          {/* Password Validation Indicators */}
          <div className="mt-2 text-xs">
            <div className="flex gap-[9%]">
              <div className="flex items-center space-x-2">
                <FaCheckCircle className={`mb-3 ${validation.hasLowercase ? 'text-green-600' : 'text-gray-500'}`} />
                <p className={validation.hasLowercase ? 'text-green-600' : 'text-gray-500'}>One lowercase character</p>
              </div>

              <div className="flex items-center space-x-2">
                <FaCheckCircle className={`mb-3 ${validation.hasNumber ? 'text-green-600' : 'text-gray-500'}`} />
                <p className={validation.hasNumber ? 'text-green-600' : 'text-gray-500'}>One number</p>
              </div>

              <div className="flex items-center space-x-2">
                <FaCheckCircle className={`mb-3 ${validation.hasUppercase ? 'text-green-600' : 'text-gray-500'}`} />
                <p className={validation.hasUppercase ? 'text-green-600' : 'text-gray-500'}>One uppercase character</p>
              </div>
            </div>

            <div className="flex gap-[12.5%]">
              <div className="flex items-center space-x-2">
                <FaCheckCircle className={`mb-3 ${validation.hasSpecialChar ? 'text-green-600' : 'text-gray-500'}`} />
                <p className={validation.hasSpecialChar ? 'text-green-600' : 'text-gray-500'}>One special character</p>
              </div>
              <div className="flex items-center space-x-2">
                <FaCheckCircle className={`mb-3 ${validation.isMinLength ? 'text-green-600' : 'text-gray-500'}`} />
                <p className={validation.isMinLength ? 'text-green-600' : 'text-gray-500'}>8 characters minimum</p>
              </div>
            </div>
          </div>
        </Form.Group>

        {/* Confirm Password Input with Visibility Toggle */}
        <Form.Group controlId="formConfirmPassword" className="Relative">
          <Form.Label>Confirm Password<span className="text-red-500">*</span></Form.Label>
          <InputGroup>
            <Form.Control
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className='!rounded-md'
              required
            />
            <InputGroup.Text
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="cursor-pointer absolute !bg-transparent !border-0 right-0 top-2 z-50"

            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        {/* Terms & Conditions */}
        <Form.Group controlId="formTerms" className="mt-3">
          <Form.Check
            type="checkbox"
            required
            className="w-[90%] text-xs"
            label={
              <>
                By registering for an account, you are consenting to our{' '}
                <a href="#" className='!underline text-xs font-semibold'>
                  Terms of Service
                </a>{' '}
                and confirming that you have reviewed and accepted the{' '}
                <a href="#" className='text-xs font-semibold'>
                  Global Privacy Statement
                </a>.
              </>
            }
          />
        </Form.Group>

        <Button className="bg-[#CFA640] mt-3 w-full flex items-center justify-center gap-3" type="submit" disabled={isSubmitting}>
          {isSubmitting ? (
            <>
              <Spinner size='sm' />
              Submitting...
            </>
          ) : (
            'Signup'
          )}
        </Button>

        {/* <p className="text-center font-medium text-base mt-2 mb-2">or</p>
        <div className="flex gap-2">
          <button className="py-2 border-1 border-solid border-[#747775] w-[50%] rounded-[6px]">
            <div className="flex justify-between px-3 items-center">
              <div className="pl-3">
                <img src={google_logo} alt="Google" />
              </div>
              <div className="-mb-4 font-medium">
                <p>Continue with Google</p>
              </div>
            </div>
          </button>

          <button className="py-2 border-1 border-solid border-[#747775] w-[50%] rounded-[6px]">
            <div className="flex justify-between px-3 items-center">
              <div className="pl-3">
                <img src={microsoft_logo} alt="Microsoft" />
              </div>
              <div className="-mb-4 font-medium">
                <p>Continue with Microsoft</p>
              </div>
            </div>
          </button>
        </div> */}
        <p className="text-center mt-3 mb-4 Barlow text-[16px] font-medium">
          Already have an account? <a className='hover:font-bold transition-all ease-linear cursor-pointer' onClick={() => (navigate("/login"))}>Login</a>
        </p>
      </Form>
    </>
  );
};

export default SignupForm;
