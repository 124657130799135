import React, { useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';
import { Spinner, Table, Button, Col, Row, Form } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/UserTable.css';
import CardComponent from '../../custom_components/card_component';
import docsLogo from '../../assets/docs.svg';
import docsLogoChecked from '../../assets/docs_check.svg';
import docsLogoLoading from '../../assets/docs_loading.svg';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast, ToastContainer } from 'react-toastify';
import { url } from '../../utils/networkconfig';


const ProgressBar = ({ width }) => {
    return (
        <div className='flex justify-center items-center'>
            <div className='w-full rounded-lg h-1 bg-[#04394F] bg-opacity-15 -mt-4'>
                <div className={`h-1 rounded-lg bg-[#04394F]`} style={{ width: `${width}%` }}></div>
            </div>
            <p>{width}%</p>
        </div>
    );
};

const ReferralDashboard = () => {
    const [counts, setCounts] = useState({});
    const [loading, setLoading] = useState(true);
    const [EmaiSending, setEmailSending] = useState(false);
    const [error, setError] = useState(null);

    const [emails, setEmails] = useState([]);
    const [emailInput, setEmailInput] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    const [referralSummary, setReferralSummary] = useState({});
    const [totalAcceptedInvitations, setTotalAcceptedInvitations] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0.0);
    const [link, setReferralLink] = useState('https://pinnacle-solution.vercel.app/code');

    const [copyStatus, setCopyStatus] = useState('Copy'); // Button text state
    const [copied, setCopied] = useState(false); // State to manage if copied

    // Copy link function
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link); // Copy the link to clipboard
        setCopied(true); // Set copied state to true
        setCopyStatus('Copied!'); // Change the button text to "Copied!"

        // Reset the button text after 3 seconds
        setTimeout(() => {
            setCopied(false); // Reset copied state
            setCopyStatus('Copy'); // Reset button text
        }, 3000);
    };


    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const user_id = user ? user.user_id : null;

    console.log(user);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchReferralSummary = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${url}/api/referral-summary/${user_id}/`);
                console.log(response.data);
                setReferralSummary(response.data);
                setTotalAcceptedInvitations(response.data.total_accepted_invitations);
                setWalletBalance(response.data.wallet_balance);
                setReferralLink(`https://pinnacleportal.co.uk/?ref=${response.data.referral_code}`)
            } catch (error) {
                setError('Error fetching referral summary');
            } finally {
                setLoading(false);
            }
        };

        if (user_id) {
            fetchReferralSummary();
        }
    }, [user_id]);

    const getStatusColor = (status) => {
        console.log("Status: ", status)
        switch (status) {
            case 'Invitation_Sent':
            case 'Invitation Sent':
                return '!bg-[#FBE2D9] text-black';
            case 'Invitation_Accepted':
            case 'Invitation Accepted':
                return '!bg-[#E8DDC0] text-black';
            case 'Application_Completed':
            case 'Application Completed':
                return '!bg-[#00B67A] text-white';
            case 'Application_Rejected':
            case 'Application Rejected':
                return '!bg-[#C5221F] text-white';
            default:
                return '!bg-[#E8DDC0] text-black'; // Default color if status doesn't match
        }
    };

    const getProgressPercentage = (status) => {
        switch (status) {
            case 'Invitation_Sent':
            case 'Invitation Sent':
                return 0; // Example percentage for Invitation Sent
            case 'Invitation_Accepted':
            case 'Invitation Accepted':
                return 25; // Example percentage for Invitation Accepted
            case 'Application_Completed':
            case 'Application Completed':
                return 100; // Complete
            case 'Application_Rejected':
            case 'Application Rejected':
                return 0; // Rejected
            default:
                return 0; // Default fallback
        }
    };

    const handleEmailInput = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            const email = e.target.value.trim();
            if (validateEmail(email) && !emails.includes(email)) {
                setEmails([...emails, email]);
                setEmailInput('');
            }
            else {
                toast.error('Please enter a valid email address');
            }
        }
    };

    const handleEmailInput_2 = (e) => {
        // Extracting email value and trimming whitespace
        const email = emailInput.trim();

        // Check if the email is valid
        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address');
            return;
        }

        // Check if the email already exists in the list
        if (emails.includes(email)) {
            toast.error('Email already added');
            return;
        }

        // Add the valid email to the list and reset the input
        setEmails([...emails, email]);
        setEmailInput('');
    };


    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleEmailDelete = (email) => {
        setEmails(emails.filter(e => e !== email));
    };

    // Function to handle the API request
    const handleSendInvite = async () => {
        if (emails.length === 0) {
            toast.error('Please add at least one email address.');
            return;
        }

        setEmailSending(true);  // Start loading

        try {
            // Make API request
            const response = await axios.post(`${url}/api/send-referral-invites/${user_id}/`, {
                email_list: emails,
            });

            // Success
            toast.success('Invitations sent successfully!');
            setEmails([]);  // Clear the email list after success
        } catch (error) {
            // Handle error
            toast.error('Failed to send invitations. Please try again.');
            console.error(error);
        } finally {
            setEmailSending(false);  // End loading
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Spinner animation="border" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div className="px-6 py-4 mx-auto">
            {/* Welcome Header */}
            <ToastContainer></ToastContainer>
            <div className='flex flex-row justify-between'>
                <div className='text-[#04394F] text-3xl font-bold'>Referral Program</div>
            </div>

            {/* Cards Section */}
            <div className="container mx-auto mt-8 border-1 border-solid border-[rgba(0, 0, 0, 0.25)] py-3">
                <div className="rounded-sm">
                    <div className='flex-grow flex-row flex justify-between items-center'>
                        <p className='font-semibold text-2xl -mb-1'>Applications Summary</p>
                    </div>

                    <div className='mt-4'>
                        <Row className="d-flex">
                            <Col className="d-flex flex-column">
                                <div className="bg-white p-4 rounded-md border-1 border-solid border-[rgba(0, 0, 0, 0.25)] flex-grow-1">
                                    <div className='flex gap-[15%]'>
                                        <div>
                                            <h4 className="text-2xl font-bold text-[#04394F]">Referral</h4>
                                            <p className="Barlow mb-4">Your referral activity</p>
                                        </div>
                                    </div>
                                    <p className="Barlow text-lg">Total number of referrals</p>
                                    <p className='text-[#04394F] text-2xl font-bold'>{totalAcceptedInvitations}</p>
                                    <p className="Barlow text-sm">
                                        This includes the clients you invited through your profile or sent them the personalized link.
                                    </p>
                                </div>
                            </Col>
                            <Col className="d-flex flex-column">
                                <div className="bg-[#005C83] p-4 rounded-md border-1 border-solid border-[rgba(0, 0, 0, 0.25)] flex-grow-1">
                                    <div className='flex gap-[15%]'>
                                        <div>
                                            <h4 className="text-2xl font-bold text-white">Balance</h4>
                                        </div>
                                    </div>
                                    <div className='justify-center items-center text-center'>
                                        <p className="text-2xl font-bold text-white">£ {walletBalance}</p>
                                        <p className='text-xs text-white mt-3'>Available</p>
                                        <Button className='w-full mt-3'>Withdraw</Button>
                                        <p className="Barlow text-xs text-white mt-2">You have to verify your personal and address information to withdraw your earnings</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="p-3 mt-4 bg-white rounded border-1 border-solid border-[rgba(0, 0, 0, 0.25)]">
                <h2 className="text-2xl font-bold mb-4">New Referral Invite</h2>
                <div className="mb-4">
                    <span className="text-[#04394F]">Through Email </span>
                    <div className="flex flex-wrap mt-2 border border-solid border-[#04394F] p-6">
                        {emails.map((email, index) => (
                            <span key={index} className="bg-[#D9E1E5] text-[#04394F] px-2 py-1 rounded-sm flex items-center mr-2 font-semibold">
                                {email}
                                <button onClick={() => handleEmailDelete(email)} className="ml-2 text-black">✖</button>
                            </span>
                        ))}
                        <input
                            type="text"
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                            onKeyDown={handleEmailInput}
                            className="rounded flex-1 !outline-none !ring-0"
                        />
                    </div>
                    <div className="mt-3 flex justify-between align-middle items-center">
                        <span className="text-black">Invite <span className='bg-[#D9E1E5] text-[#04394F] font-semibold p-2 rounded-md'>{emails.length} new referrals</span> to this retrospective</span>
                        <div className='flex gap-2'>
                            <Button
                                onClick={handleSendInvite}
                                className={`bg-gold text-white ${emails.length === 0 || EmaiSending ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={emails.length === 0 || EmaiSending}
                            >
                                {EmaiSending ? (
                                    <div className="flex items-center">
                                        <Spinner></Spinner>
                                        <span className="ml-2">Sending...</span>
                                    </div>
                                ) : (
                                    'Send Invite'
                                )}
                            </Button>
                            <Button
                                onClick={handleEmailInput_2}
                                disabled={emailInput.length < 5}
                            >
                                Add Email
                            </Button>
                        </div>
                    </div>
                    <div className='rounded-md border-t-[1px] mt-4 pt-4 border-t-[rgba(0, 0, 0, 0.4)]'>
                        <p className='text-[#04394F] text-sm Barlow'>Or, Copy the link and sent it to new client</p>
                        <div className="flex gap-4">
                            {/* Box containing the link */}
                            <div className="border rounded-md w-full pt-[1.5%] pl-[1.5%]">
                                <p className="text-sm text-gray-800">{link}</p>
                            </div>

                            {/* Copy button */}
                            <button
                                onClick={copyToClipboard}
                                className="flex items-center text-sm font-medium px-4 py-2 border-2 border-solid border-[#04394F] bg-white text-[#04394F] rounded-md hover:border-2 hover:rounded-md transition duration-200"
                            >
                                {/* Dynamic icon based on whether the link is copied */}
                                <FontAwesomeIcon icon={copied ? faCheck : faCopy} className="h-5 w-5 mr-2" />
                                {copyStatus}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Applications Table */}
            <div className="container mx-auto mt-8 border-1 border-solid border-[rgba(0, 0, 0, 0.25)] py-3">
                <div className='flex-grow flex-row flex justify-between items-center'>
                    <p className='font-semibold text-2xl -mb-1'>Track Referral Progress and Activity</p>
                </div>

                <div className="table-responsive mt-4">
                    {referralSummary.invitations.length === 0 ? (
                        <p className="text-center p-4">No Invitations Sent.</p>
                    ) : (
                        <Table hover responsive className="min-w-full auto-height client-table">
                            <thead>
                                <tr>
                                    <th>Email Address</th>
                                    <th>Date Referred</th>
                                    <th>Status</th>
                                    <th>Current Application Progress</th>
                                    <th>Earnings</th>
                                </tr>
                            </thead>
                            <tbody>
                                {referralSummary.invitations.map((invitation, index) => (
                                    <tr key={index}>
                                        <td>{invitation.referee_email}</td>
                                        <td>{new Date(invitation.date_sent).toLocaleDateString()}</td>
                                        <td>
                                            <p className={`${getStatusColor(invitation.status)} w-fit rounded-xl p-1`}>{invitation.status.replace('_', ' ')}</p>
                                        </td>
                                        <td>
                                            <ProgressBar width={getProgressPercentage(invitation.status)} />
                                        </td>
                                        <td>£ {invitation.earned_from_referral.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}

                </div>
            </div>
        </div>
    );
};

export default ReferralDashboard;
