import React, { createContext, useState } from 'react';

// Create the context
export const ProgressContext = createContext();

// Create a provider component
export const ProgressProvider = ({ children }) => {
  const [progress, setProgress] = useState({
    text: "Step 1 out of 6",
    percentage: 0 // Progress percentage
  });

  return (
    <ProgressContext.Provider value={{ progress, setProgress }}>
      {children}
    </ProgressContext.Provider>
  );
};
