import React from 'react';
import { Outlet } from 'react-router-dom';
import ProcedureBanner from '../Banner/ProcedureBanner';
import { Container, Row, Col } from 'react-bootstrap';
import ProgressBar from '../ProgressBar/progressBar';
import Logo from '../../assets/Pinnacle-Logo-Blue 1.png';
import useRequireAuth from '../hooks/useRequireAuth';

const ProcedureLayout = () => {
    useRequireAuth()
    return (
        <div className="bg-gray-50 h-screen flex flex-col items-center">
            {/* Progress Bar */}
            <div className='w-[85%] mt-[1%]'>
                <ProgressBar />
            </div>

            <div className="w-[85%] flex justify-center mt-[1%]">
                <Row className="w-full">
                    {/* Left Section: Form */}
                    <Col
                        lg={7} md={8} sm={12}
                        className="bg-white py-4 p-lg-8 shadow-lg rounded-lg relative"
                    >
                        {/* Pinnacle Logo */}
                        <img
                            src={Logo}
                            alt='Pinnacle Logo'
                            className="absolute top-4 left-4"
                        />
                        {/* Form content loaded via React Router Outlet */}
                        
                            <Outlet />
                        
                    </Col>

                    {/* Right Section: Banner (hidden on small devices) */}
                    <Col
                        lg={5} md={4}
                        className="d-none d-md-block text-white rounded-lg"
                    >
                        <ProcedureBanner />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ProcedureLayout;
