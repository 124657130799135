import React, { useContext, useEffect, useState } from 'react';
import { BannerContext } from '../../context/bannerContext';
import { ProgressContext } from '../../context/ProgressContext';
import { Button, Form, Toast, Spinner, ToastContainer } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';

const Step4 = () => {
    const { setBannerState } = useContext(BannerContext);
    const { setProgress } = useContext(ProgressContext);
    const navigate = useNavigate();
    const location = useLocation()

    const [annualRevenue, setAnnualRevenue] = useState('');
    const [netProfit, setNetProfit] = useState('');
    const [outstandingDebt, setOutstandingDebt] = useState('');
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success'); // 'success' or 'error'
    const [isUpdate, setIsUpdate] = useState(false); // Flag to determine if we are updating existing data

    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const applicationId = user ? user.application_id : null; // Use application_id instead of user_id

    useEffect(() => {
        setProgress({
            text: "3 more steps to go. Almost there!",
            percentage: 50.01 // Update percentage based on the step
        });
    }, []);

    useEffect(() => {
        setBannerState({
            title: 'Financial Information',
            information: 'This section may dynamically load based on the type of business or loan amount requested.',
        });

        if (applicationId) {
            fetchFinancialInfo(applicationId);
        }
    }, [setBannerState, applicationId]);

    const fetchFinancialInfo = async (applicationId) => {
        setLoading(true);
        try {
            const response = await axios.get(`${url}/api/applications/${applicationId}/financial-information/`);
            const { annual_revenue, net_profit, outstanding_debt } = response.data;
            setAnnualRevenue(annual_revenue);
            setNetProfit(net_profit);
            setOutstandingDebt(outstanding_debt);
            setIsUpdate(true); // Set flag to indicate that we are updating
        } catch (error) {
            console.error("Error fetching financial information:", error);
            setIsUpdate(false); // Set flag to indicate that we are creating a new record
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Basic validation
        if (!annualRevenue || !netProfit || !outstandingDebt) {
            setToastMessage("All fields are required.");
            setToastVariant('error');
            setShowToast(true);
            return;
        }

        setLoading(true);
        const fundingData = {
            application: applicationId,
            annual_revenue: annualRevenue,
            net_profit: netProfit,
            outstanding_debt: outstandingDebt,
        };

        try {
            let response;
            if (isUpdate) {
                // PUT request for updating
                response = await axios.put(`${url}/api/applications/${applicationId}/financial-information/`, fundingData);
                setToastMessage("Financial information updated successfully!");
            } else {
                // POST request for creating a new record
                response = await axios.post(`${url}/api/applications/${applicationId}/financial-information/`, fundingData);
                setToastMessage("Financial information submitted successfully!");
            }
            setToastVariant('success');
            setShowToast(true);
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.get('edit') === 'true') {
                navigate('/steps/step6'); // Navigate back to the previous page
            } else {
                navigate('/steps/step5'); // Otherwise, proceed to the next step
            }
        } catch (error) {
            console.error("Error submitting financial information:", error);
            setToastMessage("Error submitting financial information.");
            setToastVariant('error');
            setShowToast(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Form className="pt-[8%]" onSubmit={handleSubmit}>
                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%]">Financial Information</h2>
                <div className='max-h-[40vh] overflow-y-auto custom-scrollbar pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>

                        <Form.Group controlId="formAnnualRevenue">
                            <Form.Label>Annual Revenue<span className="text-red-500">*</span></Form.Label>
                            <div className='relative'>
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600 text-sm font-semibold">£</span>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your annual revenue"
                                    className='!pl-8'
                                    value={annualRevenue}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (/^\d*\.?\d*$/.test(inputValue)) {
                                            setAnnualRevenue(inputValue);
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formNetProfit">
                            <Form.Label>Net Profit<span className="text-red-500">*</span></Form.Label>
                            <div className='relative'>
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600 text-sm font-semibold">£</span>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your net profit"
                                    className='!pl-8'
                                    value={netProfit}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (/^\d*\.?\d*$/.test(inputValue)) {
                                            setNetProfit(inputValue);
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formOutstandingDebt">
                            <Form.Label>Outstanding Debt<span className="text-red-500">*</span></Form.Label>
                            <div className='relative'>
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600 text-sm font-semibold">£</span>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter any outstanding business debt"
                                    className='!pl-8'
                                    value={outstandingDebt}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (/^\d*\.?\d*$/.test(inputValue)) {
                                            setOutstandingDebt(inputValue);
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </Form.Group>

                    </div>
                </div>
                <div
                    className="absolute bottom-[22%] left-0 right-0 h-8 "
                    style={{
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.14) 16%, rgba(102, 102, 102, 0) 100%)',
                        transform: 'scaleY(1)', // This inverts the gradient vertically
                    }}
                ></div>
                <div className='flex gap-2 pt-[10%] pb-[2%] px-[10%]'>
                    <button
                        className="p-2 px-3 h-12 hover:text-white hover:bg-[#f1b00c] transition-colors ease-in flex items-center justify-center border-[1px] rounded-md border-[#111111]"
                        onClick={() => (navigate("/steps/step3"))}
                        disabled={loading}
                    >
                        <FaArrowLeft /> {/* Arrow Icon */}
                    </button>
                    <Button
                        variant="warning"
                        className="w-full h-10 text-white rounded-md"
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Continue'}
                    </Button>
                </div>
            </Form>

            <ToastContainer position="top-end">
                <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                    bg={toastVariant}
                >
                    <Toast.Body className='!text-white font-medium'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default Step4;
