import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const useRequireAuthAdmin = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = Cookies.get('admin') ? JSON.parse(Cookies.get('admin')) : null;
    
    if (!user) {
      navigate('/admin');
    }
  }, [navigate]);
};

export default useRequireAuthAdmin;
