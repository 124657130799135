import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Form, Button, Container, Row, Col, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import "../../styles/AdminTable.css"
import { url } from '../../utils/networkconfig';
import Cookies from "js-cookie";

const SuperAdmin = () => {
    const [adminName, setAdminName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminUsers, setAdminUsers] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(true);

    const fetchAdminUsers = async () => {
        try {
            const response = await axios.get(`${url}/api/admin-users/`);
            setAdminUsers(response.data);
        } catch (error) {
            toast.error('Failed to fetch admin users.');
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAdminUsers();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!adminName || !adminEmail) {
            toast.error('Both name and email are required.');
            return;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(adminEmail)) {
            toast.error('Please enter a valid email address.');
            return;
        }

        try {
            setSubmitting(true);
            const response = await axios.post(`${url}/api/create-admin/`, {
                name: adminName,
                email: adminEmail,
            });

            // Append the new admin user to the state
            const newUser = {
                id: response.data.user_id,
                admin_name: adminName,
                email: adminEmail,
            };
            setAdminUsers((prev) => [...prev, newUser]);

            toast.success('Admin user created successfully and email sent!');
            setAdminName('');
            setAdminEmail('');
        } catch (error) {
            const message = error.response?.data?.error || 'Failed to create admin user.';
            toast.error(message);
        }
        finally {
            setSubmitting(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            setDeleting(true)
            const request = await axios.delete(`${url}/api/delete-admin/${id}/`)
            // Add a delete request here when API is ready
            setAdminUsers((prev) => prev.filter((user) => user.id !== id));
            toast.success('Admin user deleted successfully.');
        } catch (error) {
            toast.error('Failed to delete admin user.');
        }
        finally {
            setDeleting(false)
        }
    };

    return (
        <Container className="mt-2">
            <ToastContainer></ToastContainer>
            <div className="bg-[#F8F8F8] p-4 rounded-md shadow-md mb-6">
                <h2 className="text-3xl font-bold text-[#04394F]">Broker Access Portal</h2>
                <p className="Barlow">
                    Manage all brokers.
                </p>
            </div>


            {/* Admin Creation Form */}
            <Form onSubmit={handleSubmit} className="mb-4">
                <Row className="align-items-center">
                    <Col md={5}>
                        <Form.Group controlId="adminName">
                            <Form.Label>Admin Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter admin name"
                                value={adminName}
                                required
                                onChange={(e) => setAdminName(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={5}>
                        <Form.Group controlId="adminEmail">
                            <Form.Label>Admin Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter admin email"
                                value={adminEmail}
                                required
                                onChange={(e) => setAdminEmail(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2} className="d-flex align-items-end">
                        <Button
                            type="submit"
                            className="w-100 mt-[30px]"
                            disabled={submitting} // Disable button when submitting
                        >
                            {submitting && <Spinner as="span" animation="border" size="sm" className="me-2" />}
                            Submit
                        </Button>
                    </Col>
                </Row>
            </Form>

            {loading ? <Spinner></Spinner> :
                <Table striped bordered hover responsive className="mt-3 super-admin-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Last Login</th>
                            <th>Login Location</th>
                            <th>Login IP Address</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {adminUsers.length > 0 ? (
                            adminUsers.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.admin_name}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        {user.last_login ? (
                                            <>
                                                {`${new Date(user.last_login).toLocaleDateString('en-GB', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric',
                                                })} - ${new Date(user.last_login).toLocaleTimeString('en-GB', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    hour12: true,
                                                })}`}
                                                {(() => {
                                                    const now = new Date(); // Current time
                                                    const lastLogin = new Date(user.last_login); // Last login time

                                                    // Use 'en-GB' locale for consistent comparison
                                                    const nowDateString = now.toLocaleDateString('en-GB');
                                                    const lastLoginDateString = lastLogin.toLocaleDateString('en-GB');

                                                    // Check if dates are the same
                                                    const isSameDate = nowDateString === lastLoginDateString;

                                                    // Time difference in milliseconds
                                                    const diffMs = now - lastLogin;
                                                    const diffMinutes = Math.floor(diffMs / (1000 * 60));
                                                    const diffHours = Math.floor(diffMinutes / 60);
                                                    const diffDays = Math.floor(diffHours / 24);

                                                    if (diffDays > 30) return ''; // Skip if older than 30 days

                                                    if (isSameDate) {
                                                        if (diffHours > 0) return ` (${diffHours} hour${diffHours > 1 ? 's' : ''} ago)`;
                                                        if (diffMinutes > 0) return ` (${diffMinutes} minute${diffMinutes > 1 ? 's' : ''} ago)`;
                                                        return ' (just now)';
                                                    } else {
                                                        return ` (${diffDays} day${diffDays > 1 ? 's' : ''} ago)`;
                                                    }
                                                })()}
                                            </>
                                        ) : (
                                            'Not Logged In'
                                        )}
                                    </td>
                                    <td>
                                        {user.last_location && user.last_location !== "N/A" ? user.last_location : 'N/A'}
                                    </td>
                                    <td>
                                        {user.last_location_ip && user.last_location_ip !== "N/A" ? user.last_location_ip : 'N/A'}
                                    </td>
                                    <td>
                                        <button
                                            className={`bg-red-500 text-white font-medium rounded-md p-2 hover:bg-red-700 text-sm ${deleting && "opacity-70"}`}
                                            onClick={() => handleDelete(user.id)}
                                            disabled={deleting}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" className="text-center">
                                    No Admin Users Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            }
        </Container>
    );
};

export default SuperAdmin;
