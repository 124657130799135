import React, { useState, useEffect, useContext } from 'react';
import { Menu } from '@headlessui/react';
import { Spinner, Table, Button } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../styles/AdminTable.css';
import SendMessageModal from '../AdminComponents/AdminSendMessage';
import SetCommissionModal from '../AdminComponents/ComissionModal';
import { useSearch } from '../../context/AdminSearchContext';
import { url } from '../../utils/networkconfig';
import { ProfileContext } from '../../context/ProfileContext';


const AdminMyApplications = () => {
    const [clients, setClients] = useState([]);  // Start with an empty array
    const [loading, setLoading] = useState(true); // Set loading to true initially
    const [error, setError] = useState(null);      // Initial error state
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [modalData, setModalData] = useState({ name: '', email: '', application_id: '', document_id: null });
    const [showCommissionModal, setShowCommissionModal] = useState(false);
    const [commissionData, setCommissionData] = useState({ clientName: '', applicationId: '' });
    const { searchQuery, setIsSearchVisible } = useSearch(); // Access search query from global state
    const { userdata } = useContext(ProfileContext)


    const navigate = useNavigate();

    useEffect(() => {
        // Fetching data from the API
        const fetchData = async () => {
            try {
                const response = await axios.get(`${url}/api/get_all_applications/`);
                console.log("Applications Data", response.data.applications);

                // Filter out applications with an application status of 'N/A'
                const filteredClients = response.data.applications.filter(client => client.application_status !== 'N/A').sort((a, b) => new Date(b.lastActivity) - new Date(a.lastActivity));;

                setClients(filteredClients);
            } catch (error) {
                setError('Error fetching data'); // Set error message
            } finally {
                setLoading(false); // Set loading to false in any case
            }
        };

        fetchData();
        setIsSearchVisible(true);
    }, []);

     // Filter clients based on the search query
     const filteredClients = clients.filter(client => 
        client.full_name.toLowerCase().includes(searchQuery.toLowerCase()) || 
        client.business_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
        client.phone_no.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case 'submitted':
                return 'bg-gray-100 text-gray-800';
            case 'in_progress':
                return 'bg-yellow-100 text-yellow-800';
            case 'under_review':
                return 'bg-blue-100 text-blue-800';
            case 'approved':
                return 'bg-green-100 text-green-800';
            case 'rejected':
                return 'bg-red-100 text-red-800';
            default:
                return 'bg-gray-100 text-gray-800';
        }
    };

    const handleSendReminder = (client) => {
        setModalData({
            name: client.full_name,
            email: client.email,
            application_id: client.application_id,
            document_id: null,  // Not setting document_id as per your requirement
        });
        setShowMessageModal(true);
    };

    const handleSetCommission = (client) => {
        setCommissionData({
            clientName: client.full_name,
            applicationId: client.application_id,
        });
        setShowCommissionModal(true);
    };

    const handleCommissionSubmit = async (amount, percentage) => {
        try {
            const response = await axios.post(`${url}/api/applications/${commissionData.applicationId}/set-funding-and-pay-commission/`, {
                commission_amount: amount,
                commission_percentage: percentage,
            });

            // Update clients state with the new commission information
            setClients(prevClients => {
                return prevClients.map(client => {
                    if (client.application_id === commissionData.applicationId) {
                        return {
                            ...client,
                            commission: {
                                commission_percentage: percentage,
                                commission_amount: amount,
                                commission_paid: true, // Assuming commission is now marked as paid
                            },
                        };
                    }
                    return client;
                });
            });

            return response.data;
        } catch (error) {
            console.error('Error setting commission:', error);
            throw error;
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Spinner animation="border" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div className="px-6 py-4 mx-auto">
            {/* Welcome Header */}
            <div className="bg-[#F8F8F8] p-4 rounded-md shadow-md mb-6">
                <h2 className="text-3xl font-bold text-[#04394F]">Welcome {userdata.fullName}!</h2>
                <p className="Barlow">
                    Manage all client onboarding activities, track progress, and send new client invitations.
                </p>
            </div>

            <div className="container mx-auto mt-8 border-1 border-solid border-[rgba(0, 0, 0, 0.25)]">
                <div className="rounded-sm">
                    {/* <div className="sticky top-0 bg-white p-4 border-b border-gray-200">
                        <div className="flex justify-between items-center">
                            <h2 className="text-2xl font-bold text-[#04394F]">Client Overview</h2>
                            <Button variant="warning">Invite New Client</Button>
                        </div>
                        <p className="Barlow">
                            Get a snapshot of all your client onboarding activities, including pending applications and recent submissions.
                        </p>
                    </div> */}

                    {/* Table */}
                    <div className="table-responsive">
                        {filteredClients.length === 0 ? (
                            <p className="text-center p-4">No applications are there.</p>
                        ) : (
                            <Table hover responsive className="min-w-full auto-height admin-table">
                                <thead>
                                    <tr>
                                        <th>Client Name</th>
                                        <th>Email</th>
                                        <th>Business Name</th>
                                        <th>Phone No.</th>
                                        <th>Application Status</th>
                                        <th>Last Activity</th>
                                        <th>Actions</th>
                                        <th>Set Commission</th> {/* New Column */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredClients.map((client, index) => (
                                        <tr key={index}>
                                            <td>{client.full_name}</td>
                                            <td>{client.email}</td>
                                            <td>{client.business_name}</td>
                                            <td>{client.phone_no}</td>
                                            <td>
                                                <span
                                                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${getStatusColor(client.application_status)}`}
                                                >
                                                    {client.application_status.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                                                </span>
                                            </td>
                                            <td>{new Date(client.lastActivity).toLocaleString()}</td>
                                            <td>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <Menu.Button>
                                                        <FaEllipsisV />
                                                    </Menu.Button>

                                                    <Menu.Items className="origin-top-right absolute right-3 bottom-[-10px] mt-2 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                                                        <div className="py-1 Barlow">
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <a
                                                                        href="#"
                                                                        onClick={() => navigate(`/admin/dashboard/application-details/${client.application_id}/`)}
                                                                        className={`block px-4 py-2 text-xs !no-underline ${active ? 'bg-gray-100' : ''}`}
                                                                    >
                                                                        View Details
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <a
                                                                        href="#"
                                                                        className={`block px-4 py-2 text-xs !no-underline ${active ? 'bg-gray-100' : ''}`}
                                                                        onClick={() => handleSendReminder(client)}
                                                                    >
                                                                        Send Reminder
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        </div>
                                                    </Menu.Items>
                                                </Menu>
                                            </td>
                                            <td>
                                                {/* Show Set Commission Button or Commission Paid text based on conditions */}
                                                {client.has_referral ? (
                                                    client.application_status.toLowerCase() === 'approved' && client.commission.commission_percentage === null ? (
                                                        <Button
                                                            variant="primary"
                                                            size="sm" // Make the button small
                                                            onClick={() => handleSetCommission(client)}
                                                        >
                                                            Set Commission
                                                        </Button>
                                                    ) : client.commission.commission_percentage !== null ? (
                                                        <span className="inline-flex items-center justify-center px-3 py-1 text-xs font-semibold text-white bg-green-500 rounded-full">
                                                            Commission Paid
                                                        </span>
                                                    ) : (
                                                        <span className="inline-flex items-center justify-center px-3 py-1 text-xs font-semibold text-white bg-red-500 rounded-full">
                                                            Await Approval
                                                        </span>
                                                    )
                                                ) : (
                                                    <span className="inline-flex items-center justify-center px-3 py-1 text-xs font-semibold text-white bg-gray-500 rounded-full">
                                                        No Referral
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </div>
            </div>
            <SendMessageModal
                show={showMessageModal}
                handleClose={() => setShowMessageModal(false)}
                name={modalData.name}
                email={modalData.email}
                application_id={modalData.application_id}
                document_id={modalData.document_id}  // This will be null as per your requirement
            />

            <SetCommissionModal
                show={showCommissionModal}
                handleClose={() => setShowCommissionModal(false)}
                clientName={commissionData.clientName}
                applicationId={commissionData.applicationId}
                onConfirm={handleCommissionSubmit}
            />
        </div>
    );
};

export default AdminMyApplications;
