import React, { useContext, useEffect, useState } from 'react';
import { BannerContext } from '../../context/bannerContext';
import { ProgressContext } from '../../context/ProgressContext';
import { Button, Form, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';

const Step2 = () => {
    const { setBannerState } = useContext(BannerContext);
    const { setProgress } = useContext(ProgressContext);
    const navigate = useNavigate();
    const location = useLocation()

    // Separate state hooks for each form field
    const [businessType, setBusinessType] = useState('');
    const [industrySector, setIndustrySector] = useState('');
    const [businessStartDate, setBusinessStartDate] = useState('');
    const [numberOfEmployees, setNumberOfEmployees] = useState('');

    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastVariant, setToastVariant] = useState('success');
    const [loading, setLoading] = useState(false); // Loading state
    const [isUpdate, setIsUpdate] = useState(false); // To determine if it's PUT or POST

    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const application_id = user ? user.application_id : null;

    // Set step progress to 2
    useEffect(() => {
        setProgress({
            text: "5 more steps to go. Almost there!",
            percentage: 16.67 // Update percentage based on the step
        });
    }, []);

    // Set banner state
    useEffect(() => {
        setBannerState({
            title: 'Business Information',
            information: 'Help us understand your business better by sharing some key details. This allows us to tailor funding options that meet your specific needs.',
        });
    }, [setBannerState]);

    // Fetch existing business details if available
    useEffect(() => {
        const fetchBusinessDetails = async () => {
            if (!application_id) return;
            setLoading(true); // Start loading

            try {
                const response = await axios.get(`${url}/api/applications/${application_id}/business-details/`);
                const data = response.data;
                
                // Set individual states with the fetched data
                setBusinessType(data.business_type || '');
                setIndustrySector(data.industry_sector || '');
                setBusinessStartDate(data.business_start_date || '');
                setNumberOfEmployees(data.number_of_employees || '');

                // Set flag to true for updating if data exists
                setIsUpdate(true);
            } catch (error) {
                console.error('Error fetching business details:', error);
                // If no business details are found, it will remain in create mode (POST)
            } finally {
                setLoading(false); // End loading
            }
        };

        fetchBusinessDetails();
    }, [application_id]);

    // Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Set the respective state based on input name
        switch (name) {
            case 'business_type':
                setBusinessType(value);
                break;
            case 'industry_sector':
                setIndustrySector(value);
                break;
            case 'business_start_date':
                setBusinessStartDate(value);
                break;
            case 'number_of_employees':
                setNumberOfEmployees(value);
                break;
            default:
                break;
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        // Validate required fields
        if (!businessType || !industrySector || !businessStartDate || !numberOfEmployees) {
            setToastMessage('All fields are required.');
            setToastVariant('danger');
            setShowToast(true);
            return;
        }

        setLoading(true); // Start loading

        try {
            if (isUpdate) {
                // PUT request to update existing business details
                await axios.put(`${url}/api/applications/${application_id}/business-details/`, {
                    business_type: businessType,
                    industry_sector: industrySector,
                    business_start_date: businessStartDate,
                    number_of_employees: numberOfEmployees,
                });
                setToastMessage('Business details updated successfully!');
            } else {
                // POST request to create new business details
                await axios.post(`${url}/api/applications/${application_id}/business-details/`, {
                    business_type: businessType,
                    industry_sector: industrySector,
                    business_start_date: businessStartDate,
                    number_of_employees: numberOfEmployees,
                });
                setToastMessage('Business details saved successfully!');
            }
            setToastVariant('success');
            setShowToast(true);
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.get('edit') === 'true') {
                navigate('/steps/step6'); // Navigate back to the previous page
            } else {
                navigate('/steps/step3'); // Otherwise, proceed to the next step
            }
        } catch (error) {
            console.error('Error saving business details:', error);
            setToastMessage('Error saving business details. Please try again.');
            setToastVariant('danger');
            setShowToast(true);
        } finally {
            setLoading(false); // End loading
        }
    };

    return (
        <div>
            {/* Toast for notifications */}
            <ToastContainer position="top-end">
                <Toast
                    onClose={() => setShowToast(false)}
                    show={showToast}
                    delay={3000}
                    autohide
                    bg={toastVariant}
                >
                    <Toast.Body className='!text-white font-medium'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            <Form className="pt-[8%]" onSubmit={handleSubmit}>
                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%] pl-[10%]">Business Details</h2>
                <div className='max-h-[40vh] overflow-y-auto custom-scrollbar pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>
                        {/* Business Type */}
                        <Form.Group controlId="formBusinessType">
                            <Form.Label>Business Type<span className="text-red-500">*</span></Form.Label>
                            <Form.Select name="business_type" value={businessType} onChange={(e) => setBusinessType(e.target.value)}>
                                <option value="">Select your option</option>
                                <option value="sole_proprietorship">Sole Proprietorship</option>
                                <option value="partnership">Partnership</option>
                                <option value="corporation">Corporation</option>
                                <option value="llc">Limited Liability Company (LLC)</option>
                                <option value="nonprofit">Nonprofit Organization</option>
                                <option value="startup">Startup</option>
                                <option value="franchise">Franchise</option>
                                <option value="cooperative">Cooperative</option>
                            </Form.Select>
                        </Form.Group>

                        {/* Industry Sector */}
                        <Form.Group controlId="formIndustrySector">
                            <Form.Label>Industry Sector<span className="text-red-500">*</span></Form.Label>
                            <Form.Select name="industry_sector" value={industrySector} onChange={(e) => setIndustrySector(e.target.value)}>
                                <option value="">Select your option</option>
                                <option value="it">Information Technology (IT)</option>
                                <option value="finance">Finance & Banking</option>
                                <option value="healthcare">Healthcare & Pharmaceuticals</option>
                                <option value="manufacturing">Manufacturing</option>
                                <option value="retail">Retail & E-commerce</option>
                                <option value="construction">Construction & Real Estate</option>
                                <option value="education">Education & Training</option>
                                <option value="agriculture">Agriculture</option>
                                <option value="transportation">Transportation & Logistics</option>
                                <option value="energy">Energy & Utilities</option>
                                <option value="hospitality">Hospitality & Tourism</option>
                                <option value="media">Media & Entertainment</option>
                            </Form.Select>
                        </Form.Group>

                        {/* Business Start Date */}
                        <Form.Group controlId="formBusinessStartDate">
                            <Form.Label>Business Start Date<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="date"
                                name="business_start_date"
                                value={businessStartDate}
                                onChange={(e) => setBusinessStartDate(e.target.value)}
                            />
                        </Form.Group>

                        {/* Number of Employees */}
                        <Form.Group controlId="formNumberOfEmployees">
                            <Form.Label>Number of Employees<span className="text-red-500">*</span></Form.Label>
                            <Form.Select name="number_of_employees" value={numberOfEmployees} onChange={(e) => setNumberOfEmployees(e.target.value)}>
                                <option value="">Select</option>
                                <option value="5 - 10">5 - 10</option>
                                <option value="10 - 20">10 - 20</option>
                                <option value="20 - 50">20 - 50</option>
                                <option value="Above 50">Above 50</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>
                <div
                    className="absolute bottom-[22%] left-0 right-0 h-8 "
                    style={{
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.14) 16%, rgba(102, 102, 102, 0) 100%)',
                        transform: 'scaleY(1)', // This inverts the gradient vertically
                    }}
                ></div>

                {/* Submit button and navigation */}
                <div className='pt-[10%] pb-[2%] px-[10%] flex gap-2'>
                    <button
                        className="p-2 px-3 h-12 hover:text-white hover:bg-[#f1b00c] transition-colors ease-in flex items-center justify-center border-[1px] rounded-md border-[#111111]"
                        onClick={() => navigate("/steps")}
                        type="button"
                    >
                        <FaArrowLeft /> {/* Arrow Icon */}
                    </button>
                    <Button
                        variant="warning"
                        className="w-full h-10 text-white rounded-md"
                        type="submit"
                        disabled={loading} // Disable button when loading
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Continue'}
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default Step2;
