import { Menu } from '@headlessui/react';
import React, { useContext, useEffect, useState } from 'react';
import { ProgressBar, Button, Row, Col, DropdownButton, Dropdown, Form } from 'react-bootstrap';
import { FaChevronDown, FaEllipsisV } from 'react-icons/fa';
import "../../styles/AdminDashboard.css"
import CardComponent from '../../custom_components/card_component';
import docsLogo from '../../assets/docs.svg';
import docsLogoChecked from '../../assets/docs_check.svg';
import docsLogoLoading from '../../assets/docs_loading.svg';
import ApplicationInsights from '../AdminComponents/ApplicationInsightsGraphComponent';
import RevenueGraph from '../AdminComponents/RevenueGraphComponent';
import OverallProgress from '../AdminComponents/OverallProgressSpeedometerComponent';
import ProgressGauge from '../AdminComponents/OverallProgressSpeedometerComponent';
import ClientsOverview from '../AdminComponents/ClientsOverviewComponent';
import InviteClientModal from '../AdminComponents/InviteClientModal';
import { url } from '../../utils/networkconfig';
import { ProfileContext } from '../../context/ProfileContext';
import { toast } from 'react-toastify';
import Cookies from "js-cookie";

const AdminDashboard = () => {
    const { userdata, setProfileUrl, setData } = useContext(ProfileContext)

    const userCookie = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const adminCookie = Cookies.get('admin') ? JSON.parse(Cookies.get('admin')) : null;

    // Prioritize 'user' over 'admin'
    const user = userCookie || adminCookie;

    // Extract user_id if available
    const user_id = user ? user.user_id : null;

    const fetchData = async () => {
        try {
            const response = await fetch(`${url}/api/admin/${user_id}/profile/`);
            const responseData = await response.json();

            console.log("Admin: ",responseData);

            if (response.ok) {
                // Setting data based on API response
                setData({
                    fullName: responseData.admin_name,
                    email: responseData.email,
                    password: '', // Assuming password is not included in the response
                    imageUrl: responseData.profile_picture || 'https://via.placeholder.com/150',
                });
                setProfileUrl(responseData.profile_picture || 'https://via.placeholder.com/150')

            } else {
                toast.error('Failed to load user data');
            }
        } catch (error) {
            toast.error('An error occurred while fetching data');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const [TotalApplicationsData, setTotalApplicationsData] = useState({
        "total_applications": 0,
        "completed": 0,
        "in_progress": 0,
        "rejected": 0
    });
    const [applicationData, setApplicationData] = useState([
        { year: '2020', count: 100 },
        { year: '2021', count: 150 },
        { year: '2022', count: 200 },
        { year: '2023', count: 250 },
    ]);

    const [revenueData, setRevenueData] = useState([
        { month: 'Jan', amount: 1000 },
        { month: 'Feb', amount: 1500 },
        { month: 'Mar', amount: 2000 },
        { month: 'Apr', amount: 2500 },
    ]);
    const [performance, setPerformance] = useState(72); // Set performance percentage


    const [clients] = useState([
        {
            name: 'John Buttler',
            email: 'johnbuttler123@gmail.com',
            business: 'XYZ Corporation',
            phone: '+44 786 1234',
            status: 'Under review',
            lastActivity: '08/29/2024 | 23:00',
        },
        {
            name: 'Alexander Thomas',
            email: 'Alexanderthomas@highway.com',
            business: 'Highway Corporation',
            phone: '+44 123 1234',
            status: 'Under review',
            lastActivity: '05/19/2024 | 13:00',
        },
        {
            name: 'Elizabeth Jones',
            email: 'elizabeth@123.com',
            business: '123 Corporation',
            phone: '+44 574 1234',
            status: 'Completed',
            lastActivity: '04/04/2024 | 18:00',
        },
        {
            name: 'Sara Kumar',
            email: 'sarakumar@abc.com',
            business: 'ABC Corporation',
            phone: '+44 123 6789',
            status: 'In progress',
            lastActivity: '04/01/2024 | 03:00',
        },
        // Add more clients if necessary
    ]);

    useEffect(() => {
        const fetchData = async () => {
            const applicationsResponse = await fetch(`${url}/api/application-counts/`);
            const response = await applicationsResponse.json();
            setTotalApplicationsData(response)
            console.log("TotalApplicationData", response);
        }

        fetchData();
    }, [])

    // useEffect(() => {
    //     // Fetch application data, revenue data, and clients data
    //     const fetchData = async () => {
    //         const applicationsResponse = await fetch('${url}/api/applications/total/');
    //         const applications = await applicationsResponse.json();
    //         // Process your data as needed
    //         setApplicationData(applications.data); // Assuming this is the correct structure

    //         // Fetch revenue data
    //         // const revenueResponse = await fetch('YOUR_REVENUE_API_URL');
    //         // const revenue = await revenueResponse.json();
    //         // setRevenueData(revenue.data); // Process revenue data accordingly

    //         // Fetch clients data
    //         // const clientsResponse = await fetch('YOUR_CLIENTS_API_URL');
    //         // const clientsData = await clientsResponse.json();
    //         // setClients(clientsData);
    //     };

    //     fetchData();
    // }, []);

    return (
        <div className="px-6 py-4 mx-auto">
            {/* Welcome Header */}
            <div className="bg-[#F8F8F8] p-4 rounded-md shadow-md mb-6">
                <h2 className="text-3xl font-bold text-[#04394F]">Welcome {userdata.fullName}!</h2>
                <p className="Barlow">
                    Manage all client onboarding activities, track progress, and send new client invitations.
                </p>
            </div>
            <div>
                <div className='flex-grow flex-row flex justify-between items-center'>
                    <p className='font-semibold text-2xl -mb-1'>Overview</p>
                    {/* <div className="position-relative" style={{ width: '200px' }}>
                        <Form.Select
                            style={{
                                backgroundColor: '#E3E3E3', // Set background color
                                color: 'black', // Set text color
                                paddingRight: '30px', // Adjust padding for the icon
                            }}
                        >
                            <option>Last 10 days</option>
                            <option>Last 20 days</option>
                            <option>Last 30 days</option>
                        </Form.Select>
                    </div> */}
                </div>
                <div className='mt-4'>
                    <Row>
                        <Col>
                            <CardComponent
                                iconSrc={docsLogo} // Replace with your SVG component
                                iconBgColor="#04394F" // Dynamic background color for the icon container
                                heading="Total Applications"
                                count={TotalApplicationsData.total_applications}
                                subtext="This includes all statuses"
                            />
                        </Col>
                        <Col>
                            <CardComponent
                                iconSrc={docsLogoChecked} // Replace with your SVG component
                                iconBgColor="#00B67A" // Dynamic background color for the icon container
                                heading="Completed Applications"
                                count={TotalApplicationsData.completed}
                                subtext="This includes approved applications"
                            />
                        </Col>
                        <Col>
                            <CardComponent
                                iconSrc={docsLogoLoading} // Replace with your SVG component
                                iconBgColor="#B38D2E" // Dynamic background color for the icon container
                                heading="In Progress Applications"
                                count={TotalApplicationsData.in_progress}
                                subtext="Includes under process applications"
                            />
                        </Col>
                        <Col>
                            <CardComponent
                                iconSrc={docsLogo} // Replace with your SVG component
                                iconBgColor="#C5221F" // Dynamic background color for the icon container
                                heading="Rejected Applications"
                                count={TotalApplicationsData.rejected}
                                subtext="These are the rejected applications."
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <Row className="g-4 mt-4 mb-10">
                <Col md={8}>
                    <div > {/* Set height */}
                        <ApplicationInsights data={applicationData} />
                    </div>
                </Col>
                <Col md={4}>
                    <div style={{ height: '490px' }}> {/* Set height */}
                        <RevenueGraph revenueData={revenueData} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={5}>
                    <div style={{ height: '400px' }}>
                        <ProgressGauge
                            total={parseInt(TotalApplicationsData.total_applications)}
                            completed={parseInt(TotalApplicationsData.completed)}
                            inProgress={parseInt(TotalApplicationsData.in_progress)}
                            rejected={parseInt(TotalApplicationsData.rejected)}
                        />
                    </div>
                </Col>
                <Col md={7}>
                    <div style={{ height: '400px' }}>
                        <ClientsOverview />
                    </div>
                </Col>
            </Row>

        </div>
    );
};

export default AdminDashboard;
