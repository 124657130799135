import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { CheckCircleIcon } from '@heroicons/react/solid'; // Tailwind Icon for Success
import Switch from 'react-switch'; // Import the react-switch component
import axios from 'axios';
import { url } from '../../utils/networkconfig';

const SendMessageModal = ({ show, handleClose, name, email, document_id, application_id,  updateDocumentStatus, setDocStatus }) => {
  const [message, setMessage] = useState('');
  const [askAdditional, setAskAdditional] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const handleSubmit = async () => {
    // Form the data to send
    const requestData = {
      application_id, // Use application_id directly
      document_id,
      message,
      ask_additional_documents: askAdditional, // Use the correct key as per your API
    };

    setIsLoading(true); // Set loading to true

    try {
      // Perform the API call to send the message
      await axios.post(`${url}/api/admin-message/send/`, requestData);
      setIsSuccess(true);
      setMessage(''); // Clear the message after sending

       // If document_id is present, update the document status
       // If document_id is present, update the document status
       if (document_id) {
        updateDocumentStatus(document_id, 'reminder_pending');  // Update status to 'reminder_pending'

        // Also update the local docStatus in ApplicationDetails
        setDocStatus((prevState) => ({
            ...prevState,
            [document_id]: 'reminder_pending',
        }));
    }

      // After 2 seconds, close the modal
    //   setTimeout(() => {
    //     setIsSuccess(false);
    //     handleClose();
    //   }, 1000);
    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setIsLoading(false); // Reset loading state
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isSuccess ? (
          <div className="flex items-center justify-center">
            <CheckCircleIcon className="h-16 w-16 text-green-500" />
            <p className="ml-4 text-green-500 font-semibold">Message Sent Successfully!</p>
          </div>
        ) : (
          <>
            <Form.Group controlId="personalizedMessage">
              <Form.Label>Personalized Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                placeholder="Write a personalized message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="focus:ring focus:ring-indigo-200"
              />
            </Form.Group>

            <div className="flex items-center justify-between mt-3">
              <p className="font-medium">Ask for additional documents</p>
              <Switch
                onChange={() => setAskAdditional(!askAdditional)}
                checked={askAdditional}
                offColor="#ccc"
                onColor="#007bff"
                uncheckedIcon={false}
                checkedIcon={false}
                height={20}
                width={48}
              />
            </div>
          </>
        )}
      </Modal.Body>

      {!isSuccess && (
        <Modal.Footer>
          <Button
            variant="primary"
            className="bg-yellow-600 text-white hover:bg-yellow-500"
            onClick={handleSubmit}
            disabled={!message || isLoading} // Disable button if the message is empty or loading
          >
            {isLoading ? (
              <>
                <Spinner animation="border" size="sm" />
                <span className="ml-2">Sending...</span>
              </>
            ) : (
              'Send Message'
            )}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default SendMessageModal;
