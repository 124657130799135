import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { FaArrowLeft, FaRegEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Spinner, Toast } from 'react-bootstrap'; // Add Toast if you want to use it for notifications
import { BannerContext } from '../../context/bannerContext';
import { ProgressContext } from '../../context/ProgressContext';
import { url } from '../../utils/networkconfig';


const Step6 = () => {
    const { setBannerState } = useContext(BannerContext);
    const { setProgress } = useContext(ProgressContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');

    // State to hold API data
    const [userData, setUserData] = useState({
        BasicContactInformation: {},
        BusinessDetails: {},
        FundingRequirements: {},
        FinancialInformation: {},
        DocumentUpload: [],
    });

    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    console.log("user: ", user);
    const applicationId = user ? user.application_id : null;
    const userId = user ? user.user_id : null;

    // Fetch data from the API on component load
    useEffect(() => {
        setProgress({
            text: "1 more step to go. Almost there!",
            percentage: 83.32 // Update percentage based on the step
        });

        if (applicationId) {
            axios.get(`${url}/api/applications/${applicationId}/`)
                .then(response => {
                    console.log("Step 6", response.data);
                    setUserData({
                        BasicContactInformation: response.data.basic_contact_information,
                        BusinessDetails: response.data.business_details,
                        FundingRequirements: response.data.funding_requirements,
                        FinancialInformation: response.data.financial_information,
                        DocumentUpload: response.data.document_uploads,
                    });
                })
                .catch(error => {
                    toast.error('Failed to fetch user data. Please try again.');
                    console.error(error);
                });
        }
    }, [applicationId, setProgress]);

    useEffect(() => {
        setBannerState({
            title: 'Upload Documents',
            information: 'Upload the necessary documents to complete your application. These files will be used to verify your business details and funding eligibility.',
        });
    }, [setBannerState]);

    // Validation for empty fields
    const handleSubmit = async () => {
        setLoading(true); // Set loading to true at the beginning

        let isValid = true;
        const requiredFields = [
            'full_name', 'business_name', 'phone_number',
            'business_type', 'industry_sector', 'business_start_date', 'number_of_employees',
            'amount_required', 'purpose', 'repayment_period', 'preferred_funding_timeline',
            'annual_revenue', 'net_profit', 'outstanding_debt',
        ];

        // Validate required fields
        for (const field of requiredFields) {
            const value = userData.BasicContactInformation[field] ||
                userData.BusinessDetails[field] ||
                userData.FundingRequirements[field] ||
                userData.FinancialInformation[field];

            if (!value || value === '' || (Array.isArray(value) && value.length === 0)) {
                isValid = false;
                toast.error(`Please fill the ${field.replace('_', ' ')} field.`);
                // Scroll to the first missing field
                document.querySelector(`[name="${field}"]`)?.scrollIntoView({ behavior: 'smooth' });
                break;
            }
        }

        // Validate document uploads
        if (userData.DocumentUpload.length === 0) {
            isValid = false;
            toast.error('Please upload at least one document.');
        }

        if (isValid) {
            try {
                // Call the API to update steps_completed flag
                const response = await axios.post(`${url}/api/update-steps-completed/`, {
                    user_id: userId , // Use the correct user ID from userData
                    steps_completed: true,
                    application_id:applicationId
                });

                // Check if the API call was successful
                if (response.status === 200) {
                    toast.success('All steps completed successfully.');
                    const updatedUser = { ...user, steps_completed: true };
                    Cookies.set('user', JSON.stringify(updatedUser), { expires: 7 });
                    // Navigate to the thanks page
                    navigate('/steps/thankyou');
                } else {
                    // Handle the error
                    toast.error('Failed to update steps completed status.');
                }
            } catch (error) {
                // Handle the error
                toast.error('An error occurred while updating steps completed status.');
                console.error('API call error:', error);
            }
        } else {
            toast.error('An error has occurred');
        }
        setLoading(false); // Set loading to false at the end
    };

    const truncateFilename = (filePath) => {
        const fileName = filePath.split('/').pop(); // Get the filename from the path
        return fileName.length > 20 ? `${fileName.slice(0, 10)}...${fileName.slice(-10)}` : fileName; // Truncate if longer than 20 characters
    };

    return (
        <div>
            <Form className="pt-[8%]">
                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%]">Review & Confirm</h2>

                {/* Basic Contact Information */}
                {/* Basic Contact Information */}
                <div className="max-h-[40vh] overflow-y-auto custom-scrollbar">
                    <div className='px-[10%] space-y-4'>
                        <div>
                            <div className="d-flex align-items-center gap-3">
                                <h3 className="text-xl font-bold">Basic Contact Information</h3>
                                <FaRegEdit className="text-gray-600 -mt-2 cursor-pointer" onClick={()=>(navigate('/steps?edit=true'))} />
                            </div>
                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold">Full Name</p>
                                    <p style={{ color: userData.BasicContactInformation.full_name ? '' : 'red' }}>
                                        {userData.BasicContactInformation.full_name || 'N/A'}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="font-semibold">Business Name</p>
                                    <p style={{ color: userData.BasicContactInformation.business_name ? '' : 'red' }}>
                                        {userData.BasicContactInformation.business_name || 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold">Phone Number</p>
                                    <p style={{ color: userData.BasicContactInformation.phone_number ? '' : 'red' }}>
                                        {userData.BasicContactInformation.phone_number || 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                        </div>

                        {/* Business Details */}
                        <div>
                            <div className="d-flex align-items-center gap-3">
                                <h3 className="text-xl font-bold">Business Details</h3>
                                <FaRegEdit className="text-gray-600 -mt-2 cursor-pointer" onClick={()=>(navigate('/steps/step2?edit=true'))} />
                            </div>
                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold">Business Type</p>
                                    <p style={{ color: userData.BusinessDetails.business_type ? '' : 'red' }}>
                                        {userData.BusinessDetails.business_type || 'N/A'}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="font-semibold">Industry Sector</p>
                                    <p style={{ color: userData.BusinessDetails.industry_sector ? '' : 'red' }}>
                                        {userData.BusinessDetails.industry_sector || 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold">Business Start Date</p>
                                    <p style={{ color: userData.BusinessDetails.business_start_date ? '' : 'red' }}>
                                        {userData.BusinessDetails.business_start_date || 'N/A'}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="font-semibold">Number of Employees</p>
                                    <p style={{ color: userData.BusinessDetails.number_of_employees ? '' : 'red' }}>
                                        {userData.BusinessDetails.number_of_employees || 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                        </div>

                        {/* Funding Requirements */}
                        <div>
                            <div className="d-flex align-items-center gap-3">
                                <h3 className="text-xl font-bold">Funding Requirements</h3>
                                <FaRegEdit className="text-gray-600 -mt-2 cursor-pointer" onClick={()=>(navigate('/steps/step3?edit=true'))} />
                            </div>
                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold">Loan Amount Required</p>
                                    <p style={{ color: userData.FundingRequirements.amount_required ? '' : 'red' }}>
                                        {userData.FundingRequirements.amount_required || 'N/A'}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="font-semibold">Purpose of Loan</p>
                                    <p style={{ color: userData.FundingRequirements.purpose ? '' : 'red' }}>
                                        {userData.FundingRequirements.purpose || 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold">Repayment Period</p>
                                    <p style={{ color: userData.FundingRequirements.repayment_period ? '' : 'red' }}>
                                        {userData.FundingRequirements.repayment_period || 'N/A'}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="font-semibold">Preferred Funding Timeline</p>
                                    <p style={{ color: userData.FundingRequirements.preferred_funding_timeline ? '' : 'red' }}>
                                        {userData.FundingRequirements.preferred_funding_timeline || 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                        </div>

                        {/* Financial Information */}
                        <div>
                            <div className="d-flex align-items-center gap-3">
                                <h3 className="text-xl font-bold">Financial Information</h3>
                                <FaRegEdit className="text-gray-600 -mt-2 cursor-pointer" onClick={()=>(navigate('/steps/step4?edit=true'))}/>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold">Annual Revenue</p>
                                    <p style={{ color: userData.FinancialInformation.annual_revenue ? '' : 'red' }}>
                                        {userData.FinancialInformation.annual_revenue || 'N/A'}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p className="font-semibold">Net Profit</p>
                                    <p style={{ color: userData.FinancialInformation.net_profit ? '' : 'red' }}>
                                        {userData.FinancialInformation.net_profit || 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <p className="font-semibold">Outstanding Debt</p>
                                    <p style={{ color: userData.FinancialInformation.outstanding_debt ? '' : 'red' }}>
                                        {userData.FinancialInformation.outstanding_debt || 'N/A'}
                                    </p>
                                </Col>
                            </Row>
                        </div>

                        {/* Document Upload */}
                        <div>
                            <div className="d-flex align-items-center gap-3">
                                <h3 className="text-xl font-bold">Document Upload</h3>
                                <FaRegEdit className="text-gray-600 -mt-2 cursor-pointer" />
                            </div>
                            <Row>
                                {userData.DocumentUpload.map((doc, index) => (
                                    <Col md={6} key={index}>
                                        <p className="font-semibold">{doc.label}</p>
                                        <a className=' underline' style={{ color: doc.file ? '' : 'red' }}>
                                            {doc.file ? truncateFilename(doc.file) : 'N/A'}
                                        </a>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
                <div
                    className="absolute bottom-[25%] left-0 right-0 h-8 "
                    style={{
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.14) 16%, rgba(102, 102, 102, 0) 100%)',
                        transform: 'scaleY(1)', // This inverts the gradient vertically
                    }}
                ></div>
                <div className='pt-[10%] px-[10%] pb-[2%] flex gap-2'>
                    <button
                        className="p-2 px-3 h-10 hover:text-white hover:bg-[#f1b00c] transition-colors ease-in flex items-center justify-center border-[1px] rounded-md border-[#111111]"
                        onClick={() => (navigate("/steps/step5"))}
                        disabled={loading}
                    >
                        <FaArrowLeft /> {/* Arrow Icon */}
                    </button>
                    <Button
                        variant="warning"
                        className="w-full h-10 text-white rounded-md"
                        type="submit"
                        disabled={loading}
                        onClick={handleSubmit}
                    >
                        {loading ? <Spinner animation="border" size="sm" /> : 'Continue'}
                    </Button>
                </div>
            </Form>
            <Toast show={showToast} onClose={() => setShowToast(false)} delay={3000} autohide>
                <Toast.Body className='!text-white font-medium'>{toastMessage}</Toast.Body>
            </Toast>
        </div>
    );
};

export default Step6;
