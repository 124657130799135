import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash, FaCheckCircle } from 'react-icons/fa';
import { BannerContext } from '../../context/bannerContext';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { setBannerState } = useContext(BannerContext);
    const [isLoading, setIsLoading] = useState(false); // Loading state for spinner
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');


    useEffect(() => {
        setBannerState({
            title: 'Welcome back to Pinnacle Business Finance.',
            information: 'Pinnacle Business Finance is a commercial finance brokerage based in Bristol. We are an unrivalled team of commercial finance experts.',
        });
    }, [setBannerState]);

    const validateForm = () => {
        const newErrors = {};
        if (!email) newErrors.email = 'Email is required.';
        if (!password) newErrors.password = 'Password is required.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     // Hardcoded admin credentials
    //     const validEmail = 'jack@pinnaclebusinessfinance.co.uk';
    //     const validPassword = 'Pinnaclebusinessfinanceltd2024';

    //     if (email === validEmail && password === validPassword) {
    //         toast.success('Login successful!');
    //         navigate("/admin/dashboard");
    //     } else {
    //         toast.error('Invalid email or password!');
    //     }
    // };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Clear any existing errors
        setErrors({});
    
        // Validate the form
        if (!validateForm()) {
            toast.error('Please fill all the required fields correctly.');
            return;
        }
    
        setIsLoading(true); // Start loading
    
        let location = 'N/A'; // Default location
        let location_ip = 'N/A';
    
        try {
            // Fetch the user's IP address
            const ipResponse = await fetch('https://api.ipify.org?format=json');
            if (ipResponse.ok) {
                const ipData = await ipResponse.json();
                const ip = ipData.ip;

                location_ip = ip;
    
                // Use the IP address to fetch the location details
                const locationResponse = await fetch(`https://ipwhois.app/json/${ip}`);
                if (locationResponse.ok) {
                    const locationData = await locationResponse.json();
                    const { country, region } = locationData; // Extract country and state/region
    
                    if (country && region) {
                        location = `${region}, ${country}`;
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    
        try {
            // Send login request to the backend
            const response = await axios.post(`${url}/api/admin/login/`, {
                email: email,
                password: password,
                location: location, // Include location in the payload
                location_ip: location_ip
            });
    
            console.log("Response Data: ", response.data);
    
            // Save user data (user_id, email) in cookies with key 'user'
            const { user_id, email: responseEmail, is_admin, is_superAdmin } = response.data;
            Cookies.set('admin', JSON.stringify(response.data), { expires: 7 }); // Cookie will expire in 7 days
            console.log(response.data);
    
            // Success: Show success toast and navigate
            toast.success('Login successful.');
    
            setTimeout(() => {
                if (is_admin || is_superAdmin) {
                    navigate("/admin/dashboard");
                } else {
                    toast.error('You do not have admin access.');
                }
            }, 1000);
        } catch (error) {
            // Handle errors and show appropriate error toasts
            if (error.response && error.response.status === 401) {
                const errorMessage = error.response.data.detail || 'Invalid credentials, please try again.';
                toast.error(errorMessage);
            } else {
                toast.error('Error occurred. You may not have Admin access.');
            }
        } finally {
            setIsLoading(false); // Stop loading
        }
    };
    

    return (
        <Form className="p-2 w-[60%]" onSubmit={handleSubmit}>
            <h2 className="text-3xl font-bold mb-2 text-center text-[#04394F]">Secure Broker Login</h2>
            <p className='text-center font-normal text-base Barlow '>Please enter your login details to access your broker dashboard and manage client onboarding.</p>

            {/* Email Input */}
            <Form.Group className="mt-2" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </Form.Group>

            {/* Password Input with Visibility Toggle */}
            <Form.Group controlId="formPassword" className="mt-3 relative">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                    <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                        className='!rounded-md'
                    />
                    <InputGroup.Text
                        onClick={() => setShowPassword(!showPassword)}
                        className="cursor-pointer absolute !bg-transparent !border-0 right-0 top-2 z-50"
                    >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroup.Text>
                </InputGroup>
            </Form.Group>
            <Form.Group controlId="formTerms" className="mt-3 flex justify-between">
                <Form.Check
                    type="checkbox"
                    label="Remember me"
                    required
                    className='text-[14px] underline text-[#04394F] Barlow'
                />
                {/* <a href='/' className='text-[14px] Barlow !text-[#04394F]'>Forget password?</a> */}
            </Form.Group>
            {/* Signup Button */}
            <Button disabled={isLoading} onClick={handleSubmit} className="bg-[#CFA640] mt-[10%] w-full text-white" type="submit">
                {isLoading ? (
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                        />
                        Logging in...
                    </>
                ) : (
                    'Login'
                )}
            </Button>

            {/* <p className="text-center mt-3 Barlow text-base font-medium">
                Don't have an account? <a onClick={() => (navigate("/"))} className=' hover:font-bold transition-all ease-linear cursor-pointer'>Signup</a>
            </p> */}
            <ToastContainer></ToastContainer>
        </Form>
    );
};

export default AdminLogin;
