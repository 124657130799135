import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Button, Form, FormControl, InputGroup, Dropdown } from 'react-bootstrap';
import { FaBars, FaSearch, FaBell } from 'react-icons/fa'; // Icons for search and notification
import { useSearch } from '../../context/AdminSearchContext';
import { useNavigate } from 'react-router-dom'; // Assuming you're using react-router for navigation
import axios from 'axios'; // Ensure axios is installed for making API requests
import { url } from '../../utils/networkconfig';
import { ProfileContext } from '../../context/ProfileContext';

const AdminNavbarComponent = ({ toggleSidebar }) => {
    const { searchQuery, setSearchQuery, isSearchVisible } = useSearch(); // Access global search state
    const [notifications, setNotifications] = useState([]); // State to hold notifications
    const navigate = useNavigate(); // For navigation
    const [loading, setLoading] = useState(true); // Loading state for notifications
    const { ProfileUrl, profilePic } = useContext(ProfileContext)


    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${url}/api/notifications/unseen/`); 
                setNotifications(response.data); // Set notifications state
            } catch (error) {
                console.error('Error fetching notifications:', error);
            } finally {
                setLoading(false); // Stop loading
            }
        };

        fetchNotifications(); // Fetch notifications on component mount
    }, []);

    const handleNotificationClick = async (notification) => {
        // Post request to update the notification to seen
        try {
            await axios.post(`${url}/api/notifications/update/`, {
                notification_id: notification.id
            });
            setNotifications((prevNotifications) =>
                prevNotifications.filter((n) => n.notification_id !== notification.notification_id)
            );
            // Navigate to application details
            navigate(`/admin/dashboard/application-details/${notification.application_id}/`);
        } catch (error) {
            console.error('Error updating notification:', error);
        }
    };

    return (
        <Navbar
            expand="lg"
            className="fixed top-0 left-0 w-full z-50 flex items-center justify-between px-4 py-3 bg-white border-b border-solid border-b-[rgba(4, 57, 79, 0.3)]"
        >
            {/* Navbar brand */}

            {/* Search bar */}
            <div className='flex flex-row w-[100%]'>
                {isSearchVisible && (
                    <Form className="d-none d-md-flex mx-4 w-[40%]">
                        <InputGroup className='relative'>
                            <FormControl
                                type="search"
                                placeholder="Search"
                                className="border-0 focus:!border-0 shadow-inner !bg-black !bg-opacity-20 !rounded-md"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <InputGroup.Text className=" !bg-transparent border-0 absolute right-0 top-2 z-50">
                                <FaSearch />
                            </InputGroup.Text>
                        </InputGroup>
                    </Form>
                )}

                <div className="flex align-items-center ml-auto">
                    {/* Notification Icon */}
                    <Dropdown>
                        <Dropdown.Toggle variant="link" className="!flex text-white me-3 -mb-7 p-0 position-relative border-0 bg-transparent shadow-none hover:!bg-transparent"
                            id="notification-dropdown"
                            style={{ boxShadow: 'none' }}>
                            <FaBell
                                size={20}
                                className="text-white"
                                style={{
                                    filter: 'drop-shadow(0 0 1px black)',
                                    WebkitFilter: 'drop-shadow(0 0 1px black)'
                                }}
                            />
                            <span className="absolute bottom-10 left-4 bg-red-600 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">
                                {notifications.length > 0 ? notifications.length : '0'}
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-end">
                            {loading ? (
                                <Dropdown.Item disabled>Loading...</Dropdown.Item>
                            ) : notifications.length === 0 ? (
                                <Dropdown.Item disabled>No notifications</Dropdown.Item>
                            ) : (
                                notifications.map(notification => (
                                    <Dropdown.Item
                                        key={notification.id}
                                        onClick={() => handleNotificationClick(notification)}
                                    >
                                        <div>
                                            <strong>Application ID: {notification.application_id}</strong>
                                        </div>
                                        <div>{notification.message}</div>
                                        <div className="text-muted">{new Date(notification.created_at).toLocaleString()}</div>
                                    </Dropdown.Item>
                                ))
                            )}
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* Profile picture */}
                    <img
                        src={ProfileUrl} // Replace this with actual profile picture source
                        alt="Profile"
                        className="rounded-circle cursor-pointer"
                        style={{ width: '35px', height: '35px' }}
                        onClick={() => navigate('/admin/dashboard/admin-settings')}

                    />



                    {/* Hamburger button for mobile */}
                    <Button
                        variant="outline-light"
                        className="d-md-none ms-3"
                        onClick={toggleSidebar}
                    >
                        <FaBars />
                    </Button>
                </div>
            </div>
        </Navbar>
    );
};

export default AdminNavbarComponent;
