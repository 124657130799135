import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const useRequireAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    
    if (!user) {
      navigate('/');
    }
  }, [navigate]);
};

export default useRequireAuth;
