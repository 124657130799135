// Banner.js
import React, { useContext } from 'react';
import comments from "../../assets/Mask group.png";
import Logo from "../../assets/Logo.png";
import Dots from "../../assets/dots.png";
import Dots_small from '../../assets/dots small.png';
import { BannerContext } from '../../context/bannerContext';
import trustpilot from '../../assets/trustpilot.svg';

const ProcedureBanner = () => {
    const { title, information } = useContext(BannerContext);

    return (
        <div className="bg-[#04394F] text-white flex flex-col justify-center items-center Montserrat text-center relative overflow-hidden h-[100%] rounded-md">
            <div className='bg-[#002636] flex p-3 text-xs gap-[25%] w-full'>
                <p className='w-[50%] text-left'>Over 142,000 5-start Trustpilot reviews in last 18 months</p>
                <img src={trustpilot} width={100} height={100} alt='trustpilot' className='-mt-4'></img>
            </div>
            <div className="flex flex-col items-center justify-center flex-grow">
                <div>
                    <h1 className="text-3xl font-bold text-center">{title}</h1>
                    <p className="text-center max-w-md mt-4 Barlow font-normal text-sm Barlow p-4">
                        {information || (
                            <>
                                <strong>Our team of specialists will:</strong><br />
                                1. <strong>Review Your Information:</strong> Ensure all details are complete and accurate.<br />
                                2. <strong>Assess Your Application:</strong> Match your needs with our financing solutions.<br />
                                3. <strong>Provide Updates:</strong> You'll receive notifications as we advance through each stage.<br /><br />
                                Remember, we're here to support you. If you have any questions or need assistance, don't hesitate to reach out.
                            </>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ProcedureBanner;
