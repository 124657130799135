import React, { useContext, useState, useEffect } from 'react';
import { Form, Button, InputGroup, Toast, ToastContainer, Spinner } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BannerContext } from '../../context/bannerContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false); // Loading state for spinner
    const navigate = useNavigate();
    const { setBannerState } = useContext(BannerContext);

    useEffect(() => {
        setBannerState({
            title: 'Your Business Finance Always Comes First',
            information: 'Pinnacle Business Finance is a commercial finance brokerage based in Bristol. We are an unrivalled team of commercial finance experts.',
        });
    }, [setBannerState]);

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!email) newErrors.email = 'Email is required.';
        if (!password) newErrors.password = 'Password is required.';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear any existing errors
        setErrors({});
        setShowErrorToast(false);
        setShowSuccessToast(false);

        // Validate the form
        if (!validateForm()) {
            return;
        }

        setIsLoading(true); // Start loading

        try {
            // Send login request to the backend
            const response = await axios.post(`${url}/api/login/`, {
                email: email,
                password: password
            });

            // Save user data (user_id, email) in cookies with key 'user'
            const { user_id, email: responseEmail, steps_completed, is_admin, is_superAdmin } = response.data;

            if (is_admin || is_superAdmin) {
                setShowErrorToast(true);
                setToastMessage('Please use Admin Portal for login');
                return;
            } 

            Cookies.set('user', JSON.stringify(response.data), { expires: 7 }); // Cookie will expire in 7 days
            console.log(response.data);
            // Success: Redirect and show success toast
            setToastMessage('Login successful.');
            setShowSuccessToast(true);

            setTimeout(() => {
                if(response.data.steps_completed === true){
                    navigate('/dashboard');
                }
                else{            
                navigate('/steps');
                }
            }, 1000);
            
            
        } catch (error) {
            // Handle errors and show error toast
            if (error.response && error.response.status === 401) {
                const errorMessage = error.response.data.detail || 'Invalid credentials, please try again.';
                setToastMessage(errorMessage);
            } 
            else if(error.response && error.response.status === 403){
                setToastMessage("Your email is not verified.");
                Cookies.set('email', email, { expires: 7 });
                navigate('/checkEmail');
            }
            else {
                setToastMessage('An unexpected error occurred, please try again.');
            }
            setShowErrorToast(true);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // Clear any existing errors
    //     setErrors({});
    //     setShowErrorToast(false);
    //     setShowSuccessToast(false);

    //     // Validate the form
    //     if (!validateForm()) {
    //         return;
    //     }

    //     setIsLoading(true); // Start loading
    //     navigate('/steps');

    // };

    return (
        <Form className="p-2 w-[60%]" onSubmit={handleSubmit}>
            <h2 className="text-3xl font-bold mb-2 text-center text-[#04394F]">Login</h2>
            <p className='text-center font-normal text-base Barlow'>Please login to continue to your account.</p>

            {/* Email Input */}
            <Form.Group className="mt-2" controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isInvalid={!!errors.email}
                    required
                />
                {errors.email && <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>}
            </Form.Group>

            {/* Password Input with Visibility Toggle */}
            <Form.Group controlId="formPassword" className="mt-3 relative">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                    <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        value={password}
                        onChange={handlePasswordChange}
                        isInvalid={!!errors.password}
                        required
                        className='!rounded-md'
                    />
                    <InputGroup.Text
                        onClick={() => setShowPassword(!showPassword)}
                        className="cursor-pointer absolute !bg-transparent !border-0 right-0 top-2 z-50"
                        >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroup.Text>
                    {errors.password && <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>}
                </InputGroup>
            </Form.Group>

            <Form.Group controlId="formTerms" className="mt-3 flex justify-between">
                <Form.Check
                    type="checkbox"
                    label="Remember me"
                    className='text-[14px] underline text-[#B38D2E] Barlow'
                />
                {/* <a href='/' className='text-[14px] Barlow'>Forget password?</a> */}
            </Form.Group>

            {/* Login Button with Spinner */}
            <Button
                className="bg-[#CFA640] mt-[10%] w-full text-white font-semibold"
                type="submit"
                disabled={isLoading} // Disable button when loading
            >
                {isLoading ? (
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="mr-2"
                        />
                        Logging in...
                    </>
                ) : (
                    'Login'
                )}
            </Button>

            <p className="text-center mt-3 Barlow text-base font-medium">
                Don't have an account? <span onClick={() => navigate("/")} className='hover:font-bold transition-all ease-linear cursor-pointer'>Signup</span>
            </p>

            {/* Success Toast */}
            <ToastContainer position="top-end" className="p-3" style={{ width: '400px' }}>
                <Toast bg="success" onClose={() => setShowSuccessToast(false)} show={showSuccessToast} delay={5000} autohide>
                    <Toast.Header closeButton={false}>
                        <strong className="me-auto">Success</strong>
                    </Toast.Header>
                    <Toast.Body className='!text-white font-medium'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>

            {/* Error Toast */}
            <ToastContainer position="top-end" className="p-3" style={{ width: '400px' }}>
                <Toast bg="danger" onClose={() => setShowErrorToast(false)} show={showErrorToast} delay={5000} autohide>
                    <Toast.Header closeButton={false}>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body className='!text-white font-medium'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Form>
    );
};

export default Login;
