import React, { useEffect, useState } from 'react';
import { ProgressBar, Button, Row, Col, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import ActivityModal from '../../custom_components/recentActivityModal';
import { url } from '../../utils/networkconfig';


const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const user_id = user ? user.user_id : null;

    useEffect(() => {
        // Fetch data from the API
        const fetchDashboardData = async () => {
            try {
                const response = await axios.get(`${url}/api/user/dashboard/${user_id}/`);
                console.log("Dashboard: ", response.data);
                setDashboardData(response.data);
            } catch (error) {
                console.error('Error fetching dashboard data', error);
            }
        };

        fetchDashboardData();
    }, []);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    // Function to get dynamic status background color
    const getStatusColor = (status) => {
        switch (status) {
            case 'submitted':
                return 'bg-[#A0C4FF] text-black font-semibold';
            case 'in_progress':
                return 'bg-[#FFD6A5]';
            case 'under_review':
                return 'bg-[#E8DDC0]';
            case 'completed':
                return 'bg-[#059A4F] text-white';
            case 'approved':
                return 'bg-[#28A745] text-white';
            case 'rejected':
                return 'bg-[#DC3545] text-white';
            default:
                return 'bg-[#E3E3E3]';
        }
    };

    if (!dashboardData) {
        return <div><span><Spinner></Spinner></span> Loading...</div>;
    }

    const { full_name, application_status, total_referrals, recent_activities, rejected_or_pending_documents } = dashboardData;

    return (
        <div className="px-6 py-4 mx-auto">
            {/* Welcome Header */}
            <div className="bg-[#F8F8F8] p-4 rounded-md shadow-md mb-6">
                <h2 className="text-3xl font-bold text-[#04394F]">Welcome {full_name}!</h2>
                <p className="Barlow">
                    Track your application and manage your documents from your personalized dashboard.
                </p>
            </div>

            {/* Application Status and Referrals */}
            <div className="flex gap-4 mb-6">
                <div className="bg-white p-4 rounded-md border-1 border-solid border-[rgba(0, 0, 0, 0.25)] w-[90%]">
                    <h4 className="text-2xl font-bold text-[#04394F]">Application Status</h4>
                    <p className="mb-4 Barlow">Track the current status of your application</p>
                    <div className="flex justify-between items-center mb-3">
                        <span className="font-semibold">Application</span>
                        <span className="font-semibold">{application_status.review_percentage}%</span>
                    </div>
                    <div className={`text-base p-2 ${getStatusColor(application_status.status)} w-[25%] text-center rounded-md mt-3 font-semibold`}>
                        {application_status.status.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}
                    </div>
                    <div className='h-2 mt-4 rounded-md bg-[#B38D2E] bg-opacity-25 w-full'>
                        <div className={`bg-[#B38D2E] h-2 w-[${application_status.review_percentage}%] rounded-md`}></div>
                    </div>
                </div>

                <div className="bg-white p-4 rounded-md border-1 border-solid border-[rgba(0, 0, 0, 0.25)]">
                    <div className='flex gap-[15%]'>
                        <div>
                            <h4 className="text-2xl font-bold text-[#04394F]">Referral</h4>
                            <p className="Barlow mb-4">Your referral activity</p>
                        </div>
                        <div className='flex-grow'>
                            <Button onClick={()=>(navigate('/dashboard/ReferralDashboard'))} className="mb-2 h-12 w-[100%] !font-semibold">New Invite</Button>
                        </div>
                    </div>
                    <p className="Barlow text-lg">Total number of referrals</p>
                    <p className='text-[#04394F] text-2xl font-bold'>{total_referrals}</p>
                    <p className="Barlow text-sm">
                        This includes the clients you invited through your profile or sent them the personalized link.
                    </p>
                </div>
            </div>

            {/* Recent Activity */}
            <div className="bg-white p-4 rounded-md mb-6 border-1 border-solid border-[rgba(0, 0, 0, 0.25)]">
                <h4 className="text-2xl font-bold text-[#04394F]">Recent Activity</h4>
                <div className='flex justify-between items-center'>
                    <p className='Barlow'>See the latest updates and actions taken on your application.</p>
                    <a href="#" className="!text-[#04394F] -mt-5 !font-medium" onClick={handleShowModal}>See all</a>
                </div>
                <div className='h-[0.5px] bg-[#000000] bg-opacity-20'></div>
                <div className="space-y-4 mt-3">
                    {recent_activities.map((activity, index) => (
                        <Row key={index} className='border-b border-b-[rgba(0, 0, 0, 0.2)]'>
                            <Col md={4}>
                                <p className="font-semibold text-base">{activity.activity}</p>
                            </Col>
                            <Col>
                                <p className="Barlow"><strong>Date:</strong> {activity.date}</p>
                            </Col>
                            <Col>
                                <p className="Barlow"><strong>Time:</strong> {activity.time}</p>
                            </Col>
                            <Col>
                                <div className={`${getStatusColor(activity.status)} text-center h-9 -mt-1 rounded-md`}>
                                    <p className="pt-[5px] font-semibold">{activity.status.replace('_', ' ').replace(/\b\w/g, char => char.toUpperCase())}</p>
                                </div>
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>

            {/* Pending Tasks */}
            <div className="bg-white p-4 rounded-md border-1 border-solid border-[rgba(0, 0, 0, 0.25)]">
                <h4 className="text-2xl font-bold text-[#04394F]">Pending Tasks</h4>
                <p className="mb-2 Barlow font-normal">
                    Complete the following tasks to ensure your application proceeds smoothly.
                </p>
                <div className='h-[0.5px] bg-[#000000] bg-opacity-20 mt-4 mb-4'></div>
                <div className="space-y-4">
                    {rejected_or_pending_documents.length === 0 ? (
                        <p className="text-center text-lg text-black font-semibold">No Pending Tasks</p>
                    ) : (
                        rejected_or_pending_documents.map((doc, index) => (
                            <Row key={index} className='border-b border-b-[rgba(0, 0, 0, 0.2)]'>
                                <div className='mb-3'>
                                    <strong className='text-lg'>Documents Rejected</strong>
                                    <p className='Barlow text-base'>Please re-upload the authentic documents required by Pinnacle Business Finance.</p>
                                </div>
                                <Col md={3}>
                                    <p className="font-semibold text-base">{doc.label}</p>
                                </Col>
                                <Col>
                                    <p className="Barlow"><strong>Reason:</strong> Not Authentic</p>
                                </Col>
                                <Col>
                                    <a className=' font-semibold cursor-pointer' onClick={() => navigate(`/dashboard/application-details/${doc.application_id}`)}>Upload New</a>
                                </Col>
                                <Col>
                                    <div className='bg-[#E8DDC0] text-center h-9 mb-[10%] -mt-1'>
                                        <p className="font-semibold pt-[5px] text-black">Required</p>
                                    </div>
                                </Col>
                            </Row>
                        ))
                    )}
                </div>
            </div>
            <ActivityModal show={showModal} handleClose={handleCloseModal} />
        </div>
    );
};

export default Dashboard;
