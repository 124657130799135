import React from 'react';

// The CardComponent accepts props to render dynamic content
const CardComponent = ({ iconSrc, heading, count, subtext, iconBgColor }) => {
    return (
        <div className="border rounded-md px-4 pt-3 flex flex-col items-start shadow-sm">
        {/* Container for the icon with dynamic background color */}
        <div
            className={`rounded-full p-3 mb-3 flex justify-center items-center`}
            style={{ backgroundColor: iconBgColor || '#E3E3E3', width: '60px', height: '60px' }}
        >
            {/* SVG icon centered inside the rounded div */}
            <img src={iconSrc} alt="Icon" />
        </div>
        <h2 className="text-black text-opacity-50 text-sm text-start">{heading}</h2>
        <h1 className="text-2xl font-semibold mt-1 text-[#04394F]">{count}</h1>
        <p className="text-black text-xs mt-2 text-start Barlow">{subtext}</p>
    </div>
    );
  };

export default CardComponent;