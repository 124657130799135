import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import Toast from 'react-bootstrap/Toast';
import { url } from '../utils/networkconfig';

const ActivityModal = ({ show, handleClose }) => {
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const user_id = user ? user.user_id : null;

    const fetchActivities = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${url}/api/activity_logs/${user_id}/`);
            setActivities(response.data);
        } catch (err) {
            setError('Failed to load activities');
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        if (show) {
            fetchActivities();
        }
    }, [show]);

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg" scrollable>
                <Modal.Header closeButton>
                    <Modal.Title>Recent Activities</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status" />
                            <p>Loading activities...</p>
                        </div>
                    ) : error ? (
                        <div className="text-danger text-center">{error}</div>
                    ) : (
                        <div className="space-y-4">
                            {activities.map((activity) => (
                                <div key={activity.id} className="border-b border-b-[rgba(0, 0, 0, 0.2)]">
                                    <p className="font-semibold text-base">{activity.activity}</p>
                                    <p className="Barlow">
                                        <strong>Date:</strong> {new Date(activity.timestamp).toLocaleDateString()}
                                    </p>
                                    <p className="Barlow">
                                        <strong>Time:</strong> {new Date(activity.timestamp).toLocaleTimeString()}
                                    </p>
                                </div>
                            ))}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {error && <Toast>{error}</Toast>}
        </>
    );
};

export default ActivityModal;
