import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Form } from 'react-bootstrap';
import { url } from '../../utils/networkconfig';

// Register necessary components for Chart.js
Chart.register(...registerables);

const ApplicationInsights = () => {
    const [data, setData] = useState(null);
    const [activeTab, setActiveTab] = useState('applications');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [days, setDays] = useState(30); // Default value is 10 days

    // Fetch data from API whenever the `days` changes
    useEffect(() => {
        setLoading(true);
        axios.get(`${url}/api/application-statistics/?days=${days}`)
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setError('Error fetching data');
                setLoading(false);
            });
    }, [days]); // Re-run when `days` value changes

    // Handle dropdown change
    const handleDaysChange = (e) => {
        setDays(e.target.value); // Update days based on dropdown
    };

    // Handle tab changes
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    // Generate gradient color for chart fill
    const getGradientFill = (ctx) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, 'rgba(179, 141, 46, 0.4)');  // 40% opacity
        gradient.addColorStop(0.5, 'rgba(179, 141, 46, 0.32)');  // 32% opacity
        gradient.addColorStop(1, 'rgba(179, 141, 46, 0)');  // 0% opacity
        return gradient;
    };

    // Format chart data based on selected key (total, success, rejected)
    const formatChartData = (key) => {
        return {
            labels: data.map(item => item.date),  // Date labels for X-axis
            datasets: [
                {
                    label: `Number of ${key.charAt(0).toUpperCase() + key.slice(1)} Applications`,
                    data: data.map(item => item[key]),
                    borderColor: '#B38D2E',  // Line stroke color
                    backgroundColor: (ctx) => getGradientFill(ctx.chart.ctx),  // Gradient fill
                    fill: true,
                    tension: 0.3,  // Smooth curve
                    borderWidth: 2,  // Line thickness
                },
            ],
        };
    };

    // Chart.js options for improved styling
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 2,
                    color: '#333',
                    font: {
                        size: 12,
                        weight: 'bold'
                    },
                },
                grid: {
                    color: '#E3E3E3'
                }
            },
            x: {
                ticks: {
                    color: '#333',
                    font: {
                        size: 12,
                        weight: 'bold'
                    }
                },
                grid: {
                    color: '#E3E3E3'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#333',
                    font: {
                        size: 14,
                        weight: 'bold'
                    }
                }
            }
        }
    };

    // Display loading or error messages
    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    // Render the component after data is fetched
    return (
        <div className="bg-white p-4 rounded-lg border">
            <div className='flex items-center justify-between mb-4'>
                <h3 className="text-lg font-bold mb-3">Application Insights</h3>
                <div className="position-relative" style={{ width: '200px' }}>
                    <Form.Select
                        style={{
                            backgroundColor: '#E3E3E3',
                            color: 'black',
                            paddingRight: '30px',
                        }}
                        value={days}
                        onChange={handleDaysChange}  // Handle dropdown change
                    >
                        <option value="30">Last 30 days</option>
                        <option value="20">Last 20 days</option>
                        <option value="10">Last 10 days</option>
                    </Form.Select>
                </div>
            </div>

            {/* Bootstrap Nav Tabs */}
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'applications' ? 'active' : ''}`}
                        href="#applications"
                        onClick={() => handleTabChange('applications')}
                    >
                        No. of Applications
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'success' ? 'active' : ''}`}
                        href="#success"
                        onClick={() => handleTabChange('success')}
                    >
                        No. of Success Applications
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'rejected' ? 'active' : ''}`}
                        href="#rejected"
                        onClick={() => handleTabChange('rejected')}
                    >
                        No. of Declined Applications
                    </a>
                </li>
            </ul>

            {/* Display the chart based on the active tab */}
            <div className="mt-4" style={{ height: '300px' }}>
                {activeTab === 'applications' && (
                    <Line data={formatChartData('total')} options={chartOptions} />
                )}
                {activeTab === 'success' && (
                    <Line data={formatChartData('success')} options={chartOptions} />
                )}
                {activeTab === 'rejected' && (
                    <Line data={formatChartData('rejected')} options={chartOptions} />
                )}
            </div>
        </div>
    );
};

export default ApplicationInsights;
