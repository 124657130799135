// src/layouts/MainLayout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import Banner from '../Banner/Banner';
import { Col } from 'react-bootstrap';
import useAuth from '../hooks/useAuth';

const MainLayout = () => {
    useAuth();
    return (
        <div className="min-h-screen flex">
        {/* Banner will take 30% of the width and remain fixed */}
        <Col md={4} className="h-screen overflow-hidden">
            <Banner  />
        </Col>

        {/* SignupForm will take 70% of the width and be scrollable */}
        <Col md={8} className="h-screen overflow-y-auto flex justify-center items-center">
            <Outlet />
        </Col>
    </div>
    );
};

export default MainLayout;
