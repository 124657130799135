import React, { useContext, useState } from 'react';
import { Button, Modal, Form, ProgressBar } from 'react-bootstrap';
import axios from 'axios';
import { DocumentUploadContext } from '../context/DocumentUploadContext';
import { url } from '../utils/networkconfig';

const UploadDocumentModal = ({ show, handleClose, applicationId, addDocumentToState }) => {
    const [label, setLabel] = useState('');
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress

    const { triggerDocumentUpdate } = useContext(DocumentUploadContext);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubmit = async () => {
        if (!label || !file) return;

        setIsUploading(true);
        setUploadProgress(0); // Reset progress

        const formData = new FormData();
        formData.append('label', label);
        formData.append('file', file);

        try {
            const response = await axios.post(
                `${url}/api/applications/${applicationId}/additional-documents/`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        setUploadProgress(progress);
                    },
                }
            );

            if (response.status === 201) {
                setUploadSuccess(true);
                setIsUploading(false);
                addDocumentToState(response.data);
                triggerDocumentUpdate();

                setTimeout(() => {
                    handleClose();
                    setUploadSuccess(false);
                    setLabel('');
                    setFile(null);
                    setUploadProgress(0);
                }, 500);
            }
        } catch (error) {
            console.error('Error uploading document:', error);
            setIsUploading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Upload Additional Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Document Label</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter document label"
                            value={label}
                            onChange={(e) => setLabel(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Button
                            variant="secondary"
                            className={`mr-2 w-full flex items-center justify-center relative ${!label ? 'opacity-50 cursor-not-allowed' : ''}`}
                            as="label"
                            disabled={!label}
                        >
                            <p className="absolute inset-0 flex items-center justify-center m-0">
                                Select File
                            </p>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                className="!hidden"
                            />
                        </Button>
                        {file && <span className="text-sm">{file.name}</span>}
                    </Form.Group>
                </Form>

                {isUploading ? (
                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                ) : (
                    uploadSuccess && (
                        <div className="flex items-center justify-center text-green-500">
                            <span>✓ Uploaded Successfully!</span>
                        </div>
                    )
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!label || !file || isUploading}
                >
                    Upload Document
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default UploadDocumentModal;
