import React, { useContext } from 'react';
import { ProgressContext } from '../../context/ProgressContext';

const ProgressBar = () => {
  const { progress } = useContext(ProgressContext);

  return (
    <div className="w-full">
      {/* Dynamically update the step text */}
      <div className="text-center text-base font-semibold text-[#04394F] mb-2">
        {progress.text}
      </div>
      
      {/* Progress bar with smooth transition */}
      <div className="w-full bg-[#e6dac1] rounded-full h-2">
        <div
          className="bg-[#B38D2E] h-2 rounded-full transition-all duration-500 ease-out"
          style={{ width: `${progress.percentage}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
