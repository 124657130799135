import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FaCheckCircle, FaEdit, FaEye, FaEyeSlash } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { url } from '../../utils/networkconfig';
import { ProfileContext } from '../../context/ProfileContext';


const Settings = () => {

    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const user_id = user ? user.user_id : null;

    const {setProfileUrl, profilePic} = useContext(ProfileContext)

    const [data, setData] = useState({
        fullName: '',
        phoneNo: '',
        email: '',
        password: '',
        currentPassword:'',
        imageUrl: profilePic, // Default image URL
    });

    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [validation, setValidation] = useState({});
    const [errors, setErrors] = useState({});

    // Fetch user data
    const fetchData = async () => {
        try {
            const response = await fetch(`${url}/api/user/${user_id}/basic-info/`);
            const responseData = await response.json();

            if (response.ok) {
                // Setting data based on API response
                setData({
                    fullName: responseData.basic_contact_info.full_name,
                    phoneNo: responseData.basic_contact_info.phone_number,
                    email: responseData.user.email,
                    password: '', // Assuming password is not included in the response
                    imageUrl: responseData.user.profile_picture || profilePic,
                });
                setProfileUrl(responseData.user.profile_picture || profilePic)

            } else {
                toast.error('Failed to load user data');
            }
        } catch (error) {
            toast.error('An error occurred while fetching data');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'fullName') {
            setErrors({
                ...errors,
                fullName: value.trim().length === 0 ? 'Full name must have at least 1 character.' : '',
            });
        }

        if (name === 'phoneNo') {
            setErrors({
                ...errors,
                phoneNo: !/^\d{11}$/.test(value) ? 'Phone number must have exactly 11 digits.' : '',
            });
        }

        setData({ ...data, [name]: value });
    };

    // Handle image upload
    const handleImageUpload = async (file) => {
        if (file) {
            setLoading(true);

            const formData = new FormData();
            formData.append('profile_picture', file);

            try {
                const response = await fetch(`${url}/api/user/${user_id}/upload-image/`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        'Authorization': `Bearer ${Cookies.get('token')}`, // Assuming you're using token-based auth
                    },
                });

                const responseData = await response.json();

                if (response.ok) {
                    setData({ ...data, imageUrl: responseData.image_url });
                    setProfileUrl(responseData.image_url || profilePic)
                    toast.success('Image uploaded successfully!');
                } else {
                    toast.error('Failed to upload image');
                }
            } catch (error) {
                toast.error('An error occurred while uploading the image');
            } finally {
                setLoading(false);
            }
        }
    };

    // Trigger image upload dialog
    const handleImageClick = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.style.display = 'none';

        input.onchange = (e) => {
            const file = e.target.files[0];
            handleImageUpload(file);
        };

        document.body.appendChild(input);
        input.click();
        document.body.removeChild(input);
    };

    // Handle password changes and validate
    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setData({ ...data, password: value });

        setValidation({
            hasLowercase: /[a-z]/.test(value),
            hasUppercase: /[A-Z]/.test(value),
            hasNumber: /\d/.test(value),
            hasSpecialChar: /[!@#$%^&*]/.test(value),
            isMinLength: value.length >= 8,
        });

        setErrors({
            ...errors,
            password: value.length === 0 ? 'Password is required.' : '',
        });
    };

    const handleCurrentPasswordChange = (e) => {
        const value = e.target.value;
        setData({ ...data, currentPassword: value });

        setErrors({
            ...errors,
            currentPassword: value.length === 0 ? 'Current password is required.' : '',
        });
    };

    const handleSave = async () => {
        const isFullNameValid = data.fullName.trim().length > 0;
    
        // Check if currentPassword or password is provided
        const isPasswordProvided = data.password.length > 0;
        const isCurrentPasswordProvided = data.currentPassword.length > 0;
    
        // Ensure both fields are either filled or empty
        const arePasswordFieldsValid =
            (isPasswordProvided && isCurrentPasswordProvided) || 
            (!isPasswordProvided && !isCurrentPasswordProvided);
    
        // Validate password only if it is provided
        const isPasswordValid = isPasswordProvided
            ? validation.hasLowercase &&
              validation.hasUppercase &&
              validation.hasNumber &&
              validation.hasSpecialChar &&
              validation.isMinLength
            : true;
    
        // If any validation fails, set errors and return
        if (!isFullNameValid || !arePasswordFieldsValid || !isPasswordValid) {
            setErrors({
                fullName: isFullNameValid ? '' : 'Full name must have at least 1 character.',
                currentPassword: arePasswordFieldsValid
                    ? ''
                    : 'Both current password and new password must be filled.',
                password: isPasswordValid || !isPasswordProvided
                    ? ''
                    : 'Password must meet the required criteria.',
            });
    
            toast.error('Please fix the validation errors before saving.');
            return;
        }
    
        setLoading(true);
    
        // Prepare the data to be sent to the API
        const updateData = {
            admin_name: data.fullName,
        };
    
        // Include password and old_password only if they are provided
        if (isPasswordProvided && isCurrentPasswordProvided) {
            updateData.password = data.password;
            updateData.old_password = data.currentPassword;
        }
    
        try {
            const response = await fetch(`${url}/api/admin/${user_id}/profile/update/`, {
                method: 'PUT',
                body: JSON.stringify(updateData),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${Cookies.get('token')}`,
                },
            });
    
            const responseData = await response.json();
    
            if (response.ok) {
                setEditMode(false);
                toast.success('Profile updated successfully!');
                fetchData(); // Refresh the user data after updating
                setData({ ...data, currentPassword: '', password: '' }); // Clear password fields
            } else {
                toast.error(responseData.error || 'Failed to update profile');
            }
        } catch (error) {
            toast.error('An error occurred while saving data');
        } finally {
            setLoading(false);
        }
    };
    


    return (
        <div className="p-5">
            <ToastContainer />
            <div className="bg-[#F8F8F8] p-4 rounded-md shadow-md mb-6">
                <h2 className="text-3xl font-bold text-[#04394F]">Settings</h2>
                <p className="Barlow">Manage Your Profile</p>
            </div>

            <div className="border rounded-lg shadow-md p-4">
                <div className="flex justify-between items-center mb-4">
                    <div className="text-center">
                        <div className="relative inline-block">
                            <img
                                src={data.imageUrl}
                                alt="Profile"
                                className="rounded-full w-24 h-24 mb-3 object-cover mx-auto"
                            />
                            <FaEdit
                                className="absolute top-0 -right-5 text-yellow-500 cursor-pointer"
                                size={20}
                                onClick={handleImageClick}
                            />
                        </div>
                        <p className="font-medium">{data.fullName}</p>
                        <p className="text-gray-500">{data.email}</p>
                    </div>
                    <Button
                        variant="warning"
                        className='!text-white'
                        onClick={() => setEditMode(!editMode)}
                        disabled={loading}
                    >
                        {editMode ? 'Cancel' : 'Edit'}
                    </Button>
                </div>

                <Form>
                    <Form.Group controlId="fullName" className="mb-3">
                        <Form.Label>Full Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="fullName"
                            placeholder="Enter full name"
                            value={data.fullName}
                            onChange={handleChange}
                            disabled={!editMode}
                            isInvalid={!!errors.fullName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.fullName}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="phoneNo" className="mb-3">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type="text"
                            name="phoneNo"
                            placeholder="Enter phone number"
                            value={data.phoneNo}
                            onChange={handleChange}
                            disabled={!editMode}
                            isInvalid={!!errors.phoneNo}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.phoneNo}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="currentPassword" className="mb-3">
                        <Form.Label>Enter Current Password</Form.Label>
                        <Form.Control
                            type="password"
                            name="currentPassword"
                            placeholder="Enter current password"
                            value={data.currentPassword}
                            onChange={handleCurrentPasswordChange}
                            disabled={!editMode}
                            isInvalid={!!errors.currentPassword}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.currentPassword}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="password" className="mb-3">
                        <Form.Label>Set a new Password</Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                placeholder="Enter password"
                                value={data.password}
                                onChange={handlePasswordChange}
                                disabled={!editMode}
                                isInvalid={!!errors.password}
                                className='!rounded-md'
                            />
                            <InputGroup.Text
                                onClick={() => setShowPassword(!showPassword)}
                                className="cursor-pointer absolute !bg-transparent !border-0 right-0 top-2 z-50"
                            >
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </InputGroup.Text>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                            {errors.password}
                        </Form.Control.Feedback>
                        <div className="mt-2 text-xs">
                            <div className="flex gap-[9%]">
                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.hasLowercase ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.hasLowercase ? 'text-green-600' : 'text-gray-500'
                                        }
                                    >
                                        One lowercase character
                                    </p>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.hasNumber ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.hasNumber ? 'text-green-600' : 'text-gray-500'
                                        }
                                    >
                                        One number
                                    </p>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.hasUppercase ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.hasUppercase ? 'text-green-600' : 'text-gray-500'
                                        }
                                    >
                                        One uppercase character
                                    </p>
                                </div>
                            </div>

                            <div className="flex gap-[12.5%]">
                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.hasSpecialChar ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.hasSpecialChar
                                                ? 'text-green-600'
                                                : 'text-gray-500'
                                        }
                                    >
                                        One special character
                                    </p>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <FaCheckCircle
                                        className={`mb-3 ${validation.isMinLength ? 'text-green-600' : 'text-gray-500'
                                            }`}
                                    />
                                    <p
                                        className={
                                            validation.isMinLength
                                                ? 'text-green-600'
                                                : 'text-gray-500'
                                        }
                                    >
                                        8 characters minimum
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Form.Group>
                </Form>

                {editMode && (
                    <div className="text-right mt-4">
                        <Button
                            variant="success"
                            className='!text-white'
                            onClick={handleSave}
                            disabled={loading}
                        >
                            Save
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Settings;
