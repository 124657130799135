import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Button, Toast, ProgressBar, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Cookies from 'js-cookie';
import { FaArrowLeft } from 'react-icons/fa';
import uploadlogo from '../../assets/cloud_upload logo.png';
import { ToastContainer, toast } from 'react-toastify';
import { url } from '../../utils/networkconfig';



const ApplicationForm = () => {
    const navigate = useNavigate();

    const { application_id } = useParams();  // Get application_id from URL params
    const user = Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null;
    const user_id = user ? user.user_id : null;

    const [fullName, setFullName] = useState('');
    const [businessName, setBusinessName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [annualRevenue, setAnnualRevenue] = useState('');
    const [netProfit, setNetProfit] = useState('');
    const [outstandingDebt, setOutstandingDebt] = useState('');

    // State variables
    const [financeType, setFinanceType] = useState('');
    const [loanAmount, setLoanAmount] = useState('');
    const [purpose, setPurpose] = useState('');
    const [repaymentPeriod, setRepaymentPeriod] = useState('');
    const [preferredFundingTimeline, setPreferredFundingTimeline] = useState('');

    const [businessType, setBusinessType] = useState('');
    const [industrySector, setIndustrySector] = useState('');
    const [businessStartDate, setBusinessStartDate] = useState('');
    const [numberOfEmployees, setNumberOfEmployees] = useState('');

    const [documents, setDocuments] = useState({});
    const [labels, setLabels] = useState([]);
    const [progress, setProgress] = useState({});
    // const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0); // Track file upload progress


    const allowedFileTypes = [
        'application/pdf',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const handleFinanceTypeChange = async (e) => {
        const selectedFinanceType = e.target.value;
        setFinanceType(selectedFinanceType);

        if (selectedFinanceType) {
            try {
                const response = await axios.get(`${url}/api/documents/get-fiance-labels/${selectedFinanceType}/`);
                if (response.status === 200) {
                    setLabels(response.data);
                }
            } catch (error) {
                console.error('Error fetching finance labels:', error);
                // setToast({ show: true, message: 'Failed to load finance labels.', type: 'error' });
                toast.error('Failed to load Finance Labels.');


            }
        } else {
            setLabels([]);
        }
    };

    // Handle file uploads
    const handleFileChange = async (event, labelId) => {
        const files = Array.from(event.target.files);
        const validFiles = files.filter(file => {
            if (file.size > 10 * 1024 * 1024) {
                toast.error('File size must be less than 10MB');
                return false;
            }
            if (!allowedFileTypes.includes(file.type)) {
                toast.error('Invalid file type. Allowed types: PDF, DOC, DOCX, CSV, XLSX');
                return false;
            }
            return true;
        });

        for (const file of validFiles) {
            await uploadFile(file, labelId);
        }
    };

    // // Upload file logic with progress tracking
    // const uploadFile = async (file, labelId) => {
    //     const formData = new FormData();
    //     formData.append('label_id', labelId);
    //     formData.append('files', file);

    //     try {
    //         setLoading(true);
    //         const response = await axios.post(`${url}/api/applications/${application_id}/documents/`, formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //             onUploadProgress: progressEvent => {
    //                 const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //                 setProgress(prev => ({ ...prev, [labelId]: percentCompleted }));
    //             },
    //         });

    //         if (response.status === 201) {
    //             setToast({ show: true, message: 'Document uploaded successfully.', type: 'success' });

    //             const newDoc = response.data[0];
    //             setDocuments(prev => {
    //                 const newDocuments = { ...prev };
    //                 if (!newDocuments[labelId]) {
    //                     newDocuments[labelId] = [];
    //                 }

    //                 const existingDoc = newDocuments[labelId].find(doc => doc.file.split('/').pop() === newDoc.file.split('/').pop());
    //                 if (!existingDoc) {
    //                     newDocuments[labelId].push(newDoc);
    //                 }

    //                 return newDocuments;
    //             });
    //         }
    //     } catch (error) {
    //         setToast({ show: true, message: 'Failed to upload document.', type: 'error' });
    //     } finally {
    //         setLoading(false);
    //         setProgress(prev => ({ ...prev, [labelId]: 0 }));
    //     }
    // };

    const uploadFile = async (file, labelId) => {
        setLoading(true);
        let percentCompleted = 0;

        // Simulate a progress interval
        const interval = setInterval(() => {
            if (percentCompleted < 100) {
                percentCompleted += 20; // Increase progress by 20% on each step
                setProgress(prev => ({ ...prev, [labelId]: percentCompleted }));
            } else {
                clearInterval(interval);
            }
        }, 500); // Simulate progress every 500ms

        // Simulating a delay for upload completion
        setTimeout(() => {
            // Create a document object to store in state
            const newDoc = {
                id: Date.now(), // Simulated unique ID for the uploaded document
                file: file, // Store the file object directly
                fileName: file.name // Store the file name
            };

            setDocuments(prev => {
                const newDocuments = { ...prev };
                if (!newDocuments[labelId]) {
                    newDocuments[labelId] = [];
                }

                // Check for existing file by filename
                const existingDoc = newDocuments[labelId].find(doc => doc.fileName === newDoc.fileName);
                if (!existingDoc) {
                    newDocuments[labelId].push(newDoc); // Add new document only if it doesn't exist
                } else {
                    // setToast({ show: true, message: 'Document with the same name already exists.', type: 'warning' });
                }

                return newDocuments;
            });

            // setToast({ show: true, message: 'Document uploaded successfully', type: 'success' });
            toast.success('Document uploaded successfully');
            setLoading(false);
            setProgress(prev => ({ ...prev, [labelId]: 0 }));
        }, 3000); // Simulate a delay of 3 seconds for upload completion
    };


    // // Handle file deletion
    // const handleDelete = async (documentId, labelId, event) => {
    //     event.preventDefault();

    //     try {
    //         await axios.delete(`${url}/api/applications/${application_id}/documents/`, {
    //             data: { document_id: documentId },
    //         });

    //         setDocuments(prev => {
    //             const newDocuments = { ...prev };
    //             newDocuments[labelId] = newDocuments[labelId].filter(doc => doc.id !== documentId);
    //             return newDocuments;
    //         });

    //         setToast({ show: true, message: 'Document deleted successfully.', type: 'success' });
    //     } catch (error) {
    //         setToast({ show: true, message: 'Failed to delete document.', type: 'error' });
    //     }
    // };

    // Simulated Handle file deletion
    const handleDelete = async (documentId, labelId, event) => {
        event.preventDefault();

        // Simulate deletion logic
        setDocuments(prev => {
            const newDocuments = { ...prev };
            newDocuments[labelId] = newDocuments[labelId].filter(doc => doc.id !== documentId);
            return newDocuments;
        });

        toast.success('Document Deleted Successfully.');
    };

    // // Validate required fields before submission
    // const validateRequiredFields = () => {
    //     for (const label of labels) {
    //         if (label.required && (!documents[label.id] || documents[label.id].length === 0)) {
    //             setToast({ show: true, message: `Please upload a document for: ${label.label}`, type: 'error' });
    //             return false;
    //         }
    //     }
    //     return true;
    // };

    // Validation for required fields and documents
    const validateRequiredFields = () => {
        // Create an array to store error messages for required fields
        const requiredFields = [
            { name: 'Full Name', value: fullName },
            { name: 'Business Name', value: businessName },
            { name: 'Phone Number', value: phoneNumber },
            { name: 'Business Type', value: businessType },
            { name: 'Industry Sector', value: industrySector },
            { name: 'Business Start Date', value: businessStartDate },
            { name: 'Number of Employees', value: numberOfEmployees },
            { name: 'Finance Type', value: financeType },
            { name: 'Loan Amount', value: loanAmount },
            { name: 'Purpose', value: purpose },
            { name: 'Repayment Period', value: repaymentPeriod },
            { name: 'Preferred Funding Timeline', value: preferredFundingTimeline },
            { name: 'Annual Revenue', value: annualRevenue },
            { name: 'Net Profit', value: netProfit },
            { name: 'Outstanding Debt', value: outstandingDebt },
        ];

        // Loop through required fields and check for missing values
        for (const field of requiredFields) {
            if (!field.value) {
                toast.error(`${field.name} is required.`);
                // setToast({ show: true, message: `${field.name} is required.`, type: 'error' });
                return false; // Return false if any required field is missing
            }
        }

        // Validate document uploads for each required label
        for (const label of labels) {
            if (label.required && (!documents[label.id] || documents[label.id].length === 0)) {
                toast.error(`Please upload a document for: ${label.label}`);
                // setToast({ show: true, message: `Please upload a document for: ${label.label}`, type: 'error' });
                return false; // Return false if any required document is missing
            }
        }

        // If all required fields and documents are present, return true
        return true;
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     // Run validation before submission
    //     if (!validateRequiredFields()) return;

    //     // Disable the button by setting the loading state to true
    //     setLoading(true);

    //     // Create the payload
    //     const payload = {
    //         basic_contact_information: {
    //             full_name: fullName,
    //             business_name: businessName,
    //             phone_number: phoneNumber,
    //         },
    //         business_details: {
    //             business_type: businessType,
    //             industry_sector: industrySector,
    //             business_start_date: businessStartDate,
    //             number_of_employees: numberOfEmployees,
    //         },
    //         funding_requirements: {
    //             finance_type: financeType,
    //             amount_required: parseFloat(loanAmount), // Ensure this is a float
    //             purpose: purpose,
    //             repayment_period: repaymentPeriod,
    //             preferred_funding_timeline: preferredFundingTimeline,
    //         },
    //         financial_information: {
    //             annual_revenue: parseFloat(annualRevenue), // Ensure this is a float
    //             net_profit: parseFloat(netProfit), // Ensure this is a float
    //             outstanding_debt: parseFloat(outstandingDebt), // Ensure this is a float
    //         },
    //         documents: Object.keys(documents).flatMap((labelId) => {
    //             return documents[labelId].map((doc) => ({
    //                 label_id: labelId,
    //                 file: doc.file, // Assuming this is the file object or Blob
    //             }));
    //         }),
    //     };

    //     console.log("Payload: ", JSON.stringify(payload)); // Log the payload

    //     try {
    //         const response = await axios.post(`${url}/api/new_application/create/${user_id}/`, payload, {
    //             headers: {
    //                 'Content-Type': 'application/json', // Use application/json for JSON payload
    //             },
    //         });

    //         if (response.status === 201) {
    //             setToast({ show: true, message: 'Application submitted successfully.', type: 'success' });
    //             navigate('/dashboard/myapplications'); // Redirect to a success page or home
    //         }
    //     } catch (error) {
    //         setToast({ show: true, message: 'Failed to submit application.', type: 'error' });
    //     } finally {
    //         // Re-enable the button by setting loading to false
    //         setLoading(false);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Run validation before submission
        if (!validateRequiredFields()) return;

        setLoading(true);

        // Create a FormData object for files
        const formData = new FormData();

        // Append documents
        Object.keys(documents).forEach((labelId) => {
            documents[labelId].forEach((doc) => {
                formData.append('documents[]', doc.file); // Append file directly
                formData.append('documents_label_id[]', labelId); // Append the label ID
            });
        });

        // Create a JSON object for other data
        const applicationData = {
            basic_contact_information: {
                full_name: fullName,
                business_name: businessName,
                phone_number: phoneNumber,
            },
            business_details: {
                business_type: businessType,
                industry_sector: industrySector,
                business_start_date: businessStartDate,
                number_of_employees: numberOfEmployees,
            },
            funding_requirements: {
                finance_type: financeType,
                amount_required: parseFloat(loanAmount),
                purpose: purpose,
                repayment_period: repaymentPeriod,
                preferred_funding_timeline: preferredFundingTimeline,
            },
            financial_information: {
                annual_revenue: parseFloat(annualRevenue),
                net_profit: parseFloat(netProfit),
                outstanding_debt: parseFloat(outstandingDebt),
            },
        };

        try {
            // Make a POST request
            const response = await axios.post(`${url}/api/new_application/create/${user_id}/`, applicationData, {
                headers: {
                    'Content-Type': 'application/json', // Use application/json for JSON
                },
            });

            // Then send the files in a separate request
            const fileResponse = await axios.post(`${url}/api/new_application/upload_files/${response.data.id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Use multipart/form-data for FormData
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted); // Update progress percentage
                },
            });

            if (fileResponse.status === 201) {
                toast.success('Application submitted successfully.');
                setTimeout(() => {
                    navigate('/dashboard/myapplications');
                }, 1500);
            }
        } catch (error) {
            console.error(error); // Log error for debugging
            toast.error('Failed to submit application.');
        } finally {
            setLoading(false);
            setUploadProgress(0);
        }
    };



    return (
        <div>
            <ToastContainer></ToastContainer>

            <Form className="pt-[3%] px-[8.5%]">
                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%]">Basic Contact Information</h2>
                <div className=' pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>
                        <Form.Group controlId="formFullName">
                            <Form.Label>Full Name<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your full name"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formBusinessName">
                            <Form.Label className="text-gray-700">Business Name<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your business name"
                                value={businessName}
                                onChange={(e) => setBusinessName(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group controlId="formPhoneNumber">
                            <Form.Label className="text-gray-700">Phone Number<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="Enter your phone number"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </div>
                </div>

                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%]">Business Information</h2>
                <div className=' pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>
                        <Form.Group controlId="formBusinessType">
                            <Form.Label>Business Type<span className="text-red-500">*</span></Form.Label>
                            <Form.Select
                                value={businessType}
                                onChange={(e) => setBusinessType(e.target.value)}
                            >
                                <option value="">Select</option>
                                <option value="sole_proprietorship">Sole Proprietorship</option>
                                <option value="partnership">Partnership</option>
                                <option value="corporation">Corporation</option>
                                <option value="limited_liability_company">Limited Liability Company</option>
                                <option value="other">Other</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="formIndustrySector">
                            <Form.Label>Industry Sector<span className="text-red-500">*</span></Form.Label>
                            <Form.Select name="industry_sector" value={industrySector} onChange={(e) => setIndustrySector(e.target.value)}>
                                <option value="">Select your option</option>
                                <option value="it">Information Technology (IT)</option>
                                <option value="finance">Finance & Banking</option>
                                <option value="healthcare">Healthcare & Pharmaceuticals</option>
                                <option value="manufacturing">Manufacturing</option>
                                <option value="retail">Retail & E-commerce</option>
                                <option value="construction">Construction & Real Estate</option>
                                <option value="education">Education & Training</option>
                                <option value="agriculture">Agriculture</option>
                                <option value="transportation">Transportation & Logistics</option>
                                <option value="energy">Energy & Utilities</option>
                                <option value="hospitality">Hospitality & Tourism</option>
                                <option value="media">Media & Entertainment</option>
                            </Form.Select>
                        </Form.Group>


                        <Form.Group controlId="formBusinessStartDate">
                            <Form.Label>Business Start Date<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="date"
                                value={businessStartDate}
                                onChange={(e) => setBusinessStartDate(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formNumberOfEmployees">
                            <Form.Label>Number of Employees<span className="text-red-500">*</span></Form.Label>
                            <Form.Select name="number_of_employees" value={numberOfEmployees} onChange={(e) => setNumberOfEmployees(e.target.value)}>
                                <option value="">Select</option>
                                <option value="5 - 10">5 - 10</option>
                                <option value="10 - 20">10 - 20</option>
                                <option value="20 - 50">20 - 50</option>
                                <option value="Above 50">Above 50</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%]">Funding Requirements</h2>
                <div className=' pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>
                        <Form.Group controlId="formFinanceType">
                            <Form.Label>Finance Type<span className="text-red-500">*</span></Form.Label>
                            <Form.Select
                                value={financeType}
                                onChange={handleFinanceTypeChange}
                            >
                                <option value="">Select</option>
                                <option value="business_loan">Business Loan</option>
                                <option value="invoice_finance">Invoice Finance</option>
                                <option value="revolving_credit">Revolving Credit</option>
                                <option value="merchant_cash_advance">Merchant Cash Advance</option>
                                <option value="bridging_loan">Bridging Loan</option>
                                <option value="commercial_mortgage">Commercial Mortgage</option>
                                <option value="development_finance">Development Finance</option>
                                <option value="asset_finance">Asset Finance</option>
                                <option value="other">Other</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="formLoanAmount">
                            <Form.Label>Amount Required<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the amount you are requesting"
                                value={loanAmount}
                                onChange={(e) => {
                                    const input = e.target.value;
                                    if (/^\d*$/.test(input)) {
                                        setLoanAmount(input);
                                    }
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formPurpose">
                            <Form.Label>Purpose<span className="text-red-500">*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter your text here"
                                value={purpose}
                                onChange={(e) => setPurpose(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="formRepaymentPeriod">
                            <Form.Label>Repayment Period<span className="text-red-500">*</span></Form.Label>
                            <Form.Select
                                value={repaymentPeriod}
                                onChange={(e) => setRepaymentPeriod(e.target.value)}
                            >
                                <option value="">Select</option>
                                <option value="1 - 2 Years">1 - 2 Years</option>
                                <option value="2 - 3 Years">2 - 3 Years</option>
                                <option value="3 - 5 Years">3 - 5 Years</option>
                                <option value="More than 5 Years">More than 5 Years</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="formPreferredFundingTimeline">
                            <Form.Label>Preferred Funding Timeline<span className="text-red-500">*</span></Form.Label>
                            <Form.Select
                                value={preferredFundingTimeline}
                                onChange={(e) => setPreferredFundingTimeline(e.target.value)}
                            >
                                <option value="">Select</option>
                                <option value="Within 1 Month">Within 1 Month</option>
                                <option value="1 - 3 Months">1 - 3 Months</option>
                                <option value="3 - 6 Months">3 - 6 Months</option>
                                <option value="More than 6 Months">More than 6 Months</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                <h2 className="text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%]">Financial Information</h2>
                <div className=' pb-4'>
                    <div className='pl-[10%] pr-[10%] space-y-4'>

                        <Form.Group controlId="formAnnualRevenue">
                            <Form.Label>Annual Revenue<span className="text-red-500">*</span></Form.Label>
                            <div className='relative'>
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600 text-sm font-semibold">£</span>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your annual revenue"
                                    className='!pl-8'
                                    value={annualRevenue}
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        if (/^\d*$/.test(input)) {
                                            setAnnualRevenue(input);
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formNetProfit">
                            <Form.Label>Net Profit<span className="text-red-500">*</span></Form.Label>
                            <div className='relative'>
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600 text-sm font-semibold">£</span>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your net profit"
                                    className='!pl-8'
                                    value={netProfit}
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        if (/^\d*$/.test(input)) {
                                            setNetProfit(input);
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formOutstandingDebt">
                            <Form.Label>Outstanding Debt<span className="text-red-500">*</span></Form.Label>
                            <div className='relative'>
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-600 text-sm font-semibold">£</span>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter any outstanding business debt"
                                    className='!pl-8'
                                    value={outstandingDebt}
                                    onChange={(e) => {
                                        const input = e.target.value;
                                        if (/^\d*$/.test(input)) {
                                            setOutstandingDebt(input);
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                        </Form.Group>

                    </div>
                </div>

                <h2 className={`text-3xl font-bold mb-2 text-left text-[#04394F] px-[10%] ${!financeType && 'opacity-50'}`}>Upload Documents</h2>
                <div className={` pb-4 ${!financeType && 'opacity-50'}`}>
                    <div className='pl-[10%] pr-[10%] space-y-4'>
                        {labels.map(label => (
                            <div key={label.id}>
                                <Form.Group controlId={`form${label.id}`}>
                                    <Form.Label className="text-lg font-semibold">
                                        {label.label} {label.required && <span className="text-red-500">*</span>}
                                    </Form.Label>

                                    <div className="border-2 border-dashed border-gray-400 rounded-lg flex items-center justify-around gap-3 px-2 py-3">
                                        <div className="flex items-center gap-3">
                                            <div className="text-gray-500">
                                                <img src={uploadlogo} width={30} height={30} alt="upload logo" />
                                            </div>
                                            <div>
                                                <p className="text-sm text-gray-500 mb-0">Select a file or drag and drop here</p>
                                                <p className="text-xs text-gray-400">PDF, DOC, DOCX, XLSX, CSV, file size no more than 10MB</p>
                                            </div>
                                        </div>
                                        <div className="flex items-center">
                                            <input
                                                type="file"
                                                id={`input${label.id}`}
                                                onChange={e => handleFileChange(e, label.id)}
                                                accept=".pdf,.doc,.docx,.xlsx,.csv"
                                                className="hidden"
                                                multiple
                                            />
                                            <label
                                                htmlFor={`input${label.id}`}
                                                className="text-sm bg-[#FBFDFE] text-[#B38D2E] py-2 px-4 rounded cursor-pointer hover:bg-[#B38D2E] border-1 border-[#B38D2E] hover:text-white hover:font-semibold transition duration-300"
                                            >
                                                Upload
                                            </label>
                                        </div>
                                    </div>

                                    {/* Display uploaded documents */}
                                    {documents[label.id] && documents[label.id].length > 0 && (
                                        <div className="mt-2">
                                            <h5 className="text-lg font-semibold">Uploaded Documents:</h5>
                                            <ul className="list-disc pl-5 space-y-2">
                                                {documents[label.id].map(doc => (
                                                    <li key={doc.id} className="flex justify-between items-center">
                                                        {/* doc.file.split('/').pop() */}
                                                        <a href={doc.file} target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">{doc.fileName} </a>
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            onClick={e => handleDelete(doc.id, label.id, e)}
                                                        >
                                                            Delete
                                                        </Button>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}

                                    {/* Show progress bar if there's progress */}
                                    {progress[label.id] > 0 && (
                                        <div className="mt-2">
                                            <ProgressBar now={progress[label.id]} label={`${progress[label.id]}%`} />
                                        </div>
                                    )}
                                </Form.Group>
                            </div>
                        ))}
                    </div>
                </div>
            </Form>
            <div className="flex justify-center items-center my-4">
                <Button
                    onClick={handleSubmit}
                    className="w-2/3"
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />{' '}
                            Submitting... {uploadProgress}%
                        </>
                    ) : (
                        'Submit'
                    )}
                </Button>
            </div>
        </div>
    );
};

export default ApplicationForm;
